import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CalendarKendo = _resolveComponent("CalendarKendo")

  return (_ctx.loaded)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode(_component_CalendarKendo, {
          changeDate: true,
          onOnselectslot: _ctx.onselectslot,
          onOpenOrder: _ctx.openOrder,
          product: _ctx.productId,
          priceTypeId: _ctx.priceTypeId,
          customerId: _ctx.customerId,
          selectedDateProp: _ctx.date,
          date: _ctx.date.split('T')[0],
          selectedSlotId: _ctx.slotId,
          editExistOrder: true
        }, null, 8, ["onOnselectslot", "onOpenOrder", "product", "priceTypeId", "customerId", "selectedDateProp", "date", "selectedSlotId"])
      ]))
    : _createCommentVNode("", true)
}