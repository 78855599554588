import swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import { api } from '@/services/Api';
import { CalendarSlot, CalendarStopSale } from '@/models/Calendar';
import { DayOfWeekKendo, DayOfWeekToIso } from '@/models/Enums';
import _ from 'lodash';
import Q from 'q';
import mixins from '@/mixin';

export default function() {
    const { t } = useI18n();

    async function saveSlot(
        model: CalendarSlot,
        parentSlotDate: string,
        ignoreOrderWarning = false,
        ignoreStopSaleWarning = false,
        moveOrders = false
    ): Promise<CalendarSlot | null> {
        const resp = await api.saveCalendarSlot(model, parentSlotDate, ignoreOrderWarning, ignoreStopSaleWarning, moveOrders);
        if (resp.errorMessage || !resp.data) {
            const continueLoading = swal.isVisible() && swal.isLoading();
            if (resp.errorCode == 1) {
                const swalResult = await swal.fire({
                    icon: 'info',
                    title: t('calendar.v3.warning.has_orders'),
                    customClass: {
                        confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                        denyButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                    },
                    buttonsStyling: false,
                    showDenyButton: true,
                    showCloseButton: true,
                    confirmButtonText: t('alert.yesContinue'),
                    denyButtonText: t('button.cancel')
                });
                if (!swalResult.isConfirmed) return null;
                ignoreOrderWarning = true;
            }
            if (resp.errorCode == 3) {
                const swalResult = await swal.fire({
                    icon: 'info',
                    title: t('calendar.v3.warning.has_stopsales', {
                        start: mixins.methods.getDateToTitle(model.startDate.toString()),
                        end: mixins.methods.getDateToTitle((model.repeatEndDate || model.startDate).toString())
                    }),
                    customClass: {
                        confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                        denyButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                    },
                    buttonsStyling: false,
                    showDenyButton: true,
                    showCloseButton: true,
                    confirmButtonText: t('alert.yesContinue'),
                    denyButtonText: t('button.cancel')
                });
                if (!swalResult.isConfirmed) return null;
                ignoreStopSaleWarning = true;
            } else {
                swal.fire({
                    title: resp.errorMessage || 'Error',
                    icon: 'error'
                });
                return null;
            }
            if (continueLoading) {
                await Q.delay(150);
                swal.showLoading();
            }
            return await saveSlot(model, parentSlotDate, ignoreOrderWarning, ignoreStopSaleWarning, moveOrders);
        }
        return resp.data.slot;
    }

    async function createStopSale(
        model: CalendarStopSale,
        allSlots: boolean,
        ignoreOrderWarning = false,
        ignoreDeleteWarning = false
    ): Promise<{ stopSale: CalendarStopSale[]; deletedSlotIds: number[] } | null> {
        const resp = await api.createCalendarStopSale(model, ignoreOrderWarning, ignoreDeleteWarning);
        if (resp.errorMessage || !resp.data) {
            const continueLoading = swal.isVisible() && swal.isLoading();
            if (resp.errorCode == 1) {
                const swalResult = await swal.fire({
                    icon: 'info',
                    title: allSlots ? t('calendar.v3.warning.has_orders_some_slot') : t('calendar.v3.warning.has_orders'),
                    customClass: {
                        confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                        denyButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                    },
                    buttonsStyling: false,
                    showDenyButton: true,
                    showCloseButton: true,
                    confirmButtonText: t('alert.yesContinue'),
                    denyButtonText: t('button.cancel')
                });
                if (!swalResult.isConfirmed) return null;
                ignoreOrderWarning = true;
            } else if (resp.errorCode == 2) {
                const swalResult = await swal.fire({
                    icon: 'info',
                    title: allSlots ? t('calendar.v3.warning.confirm_remove_some_slot') : t('calendar.v3.warning.confirm_remove'),
                    customClass: {
                        confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                        denyButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                    },
                    buttonsStyling: false,
                    showDenyButton: true,
                    showCloseButton: true,
                    confirmButtonText: t('alert.yesContinue'),
                    denyButtonText: t('button.cancel')
                });
                if (!swalResult.isConfirmed) return null;
                ignoreDeleteWarning = true;
            } else {
                swal.fire({
                    title: resp.errorMessage || 'Error',
                    icon: 'error'
                });
                return null;
            }
            if (continueLoading) {
                await Q.delay(150);
                swal.showLoading();
            }
            return await createStopSale(model, allSlots, ignoreOrderWarning, ignoreDeleteWarning);
        }
        return { stopSale: resp.data.stopSales, deletedSlotIds: resp.data.deletedSlotIds };
    }

    async function createStopSaleFull(slotId: string, ignoreOrderWarning = false, ignoreDeleteWarning = false) {
        const slot = new CalendarStopSale();
        slot.slotId = slotId;
        return await createStopSale(slot, ignoreOrderWarning, ignoreDeleteWarning);
    }

    async function revertSlot(id: string, ignoreWarning = false): Promise<boolean | null> {
        const resp = await api.revertCalendarSlot(id, ignoreWarning);
        if (resp.errorMessage || !resp.data) {
            const continueLoading = swal.isVisible() && swal.isLoading();
            if (resp.errorCode == 1) {
                const swalResult = await swal.fire({
                    icon: 'info',
                    title: t('calendar.v3.slot.confirm-revert-stopsale'),
                    customClass: {
                        confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                        denyButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                    },
                    buttonsStyling: false,
                    showDenyButton: true,
                    showCloseButton: true,
                    confirmButtonText: t('alert.yesContinue'),
                    denyButtonText: t('button.cancel')
                });
                if (!swalResult.isConfirmed) return null;
                ignoreWarning = true;
                await Q.delay(150);
                swal.showLoading();
            } else {
                swal.fire({
                    title: resp.errorMessage || 'Error',
                    icon: 'error'
                });
                return null;
            }
            if (continueLoading) {
                await Q.delay(150);
                swal.showLoading();
            }
            return await revertSlot(id, ignoreWarning);
        }
        return resp.data;
    }

    const weekDayNames = DayOfWeekKendo;
    const revertedDays = _.invert(DayOfWeekToIso);
    function getDayFromDate(date: Date) {
        return revertedDays[((date.getDay() + 7) % 8) + 1];
    }

    return {
        weekDayNames,
        revertedDays,
        getDayFromDate,
        revertSlot,
        saveSlot,
        createStopSale,
        createStopSaleFull
    };
}
