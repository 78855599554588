
import { ref, Ref, defineComponent, onMounted, nextTick, onUnmounted, computed } from 'vue';
import { api } from '@/services/Api';
import router from '@/router';
import { generalStore } from '@/store';
import moment from 'moment';
import swal from 'sweetalert2';
import { CalendarSlot, CalendarSlotKendo, CalendarSchedulerEvent, CalendarEvent, CalendarStopSale } from '@/models/Calendar';
import CalendarSlotModal, { CalendarMode } from './CalendarSlotModal.vue';
import CalendarOrderModal from './CalendarOrderModal.vue';
import CalendarStopSaleModal from './CalendarStopSaleModal.vue';
import CalendarSlotHistoryModal from './CalendarSlotHistoryModal.vue';
import SlotEmployeesModal from './SlotEmployeesModal.vue';
import OverlayModal from './OverlayModal.vue';
import CheckBoxInput from '@/components/CheckBoxInput.vue';
import { ProductListItem } from '@/models/Product';
import { useI18n } from 'vue-i18n';
import { ApiResult } from '@/services/BaseApi';
import { CalendarAvailabilityResponse, ChangeSlotEmployeeIcon, IdName, PriceType } from '@/models/Interfaces';
import { OrdersFilter, Slot } from '@/models/Order';
import { Employee, AssignedEmployee } from '@/models/Employee';
import useSchedulerExtensions from '@/modules/useSchedulerExtensions';
import useSlots from '@/modules/useSlots';
import useOrder from '@/modules/useOrder';
import _ from 'lodash';
import Q from 'q';
import mixin from '@/mixin';
import { AssignEmployeeRequest } from '@/models/Interfaces';
import SaveSendNotificationSmsEmailTemplate from '@/components/Modals/SaveSendNotificationSmsEmailTemplate.vue';
import { SendEmailSmsAction, toResource } from '@/models/MessageTemplate';

const kendoQuery: JQueryStatic = (window.kendo as any).jQuery;

const headerHeight = 56;

class TooltipSlotInfo {
    product = '';
    startDate: Date | string = '';
    startTime = '';
    endTime = '';
    content = '';
    visible = false;
}

interface CalendarOptions extends kendo.ui.CalendarOptions {
    animation?: {
        horizontal?: {
            effects?: string;
            reverse?: boolean;
            duration?: number;
            divisor?: number;
        };
        vertical?: {
            effects?: string;
            duration?: number;
        };
    };
}

export default defineComponent({
    props: {
        date: {
            type: String
        },
        view: {
            type: String
        },
        product: {
            type: String
        },
        changeDate: {
            type: Boolean,
            default: false
        },
        selectedDateProp: {
            type: String
        },
        selectedSlotId: {
            type: String
        },
        removePastSlots: {
            type: Boolean,
            default: false
        },
        editExistOrder: {
            type: Boolean,
            default: false
        },
        employeeSlotId: {
            type: String
        }
    },
    components: {
        CalendarOrderModal,
        OverlayModal,
        CalendarSlotModal,
        CalendarStopSaleModal,
        CalendarSlotHistoryModal,
        SlotEmployeesModal,
        CheckBoxInput,
        SaveSendNotificationSmsEmailTemplate
    },
    setup(props, { emit }: any) {
        document.documentElement.style.setProperty('--header-height', headerHeight + 'px');
        const kendoWrapper: Ref<HTMLElement | undefined> = ref();
        const calendarElement: Ref<HTMLElement | undefined> = ref();
        const modal = ref<InstanceType<typeof CalendarSlotModal>>();
        const modalOrder = ref<InstanceType<typeof CalendarOrderModal>>();
        const modalHistory = ref<InstanceType<typeof CalendarSlotHistoryModal>>();
        const modalSlotEmployees = ref<InstanceType<typeof SlotEmployeesModal>>();

        const modalStopSale = ref<InstanceType<typeof CalendarStopSaleModal>>();
        const modalSaveSendNotificationSmsEmailTemplate = ref<InstanceType<typeof SaveSendNotificationSmsEmailTemplate>>();
        let refreshAvailability = false;
        const wasCalcViewHour = ref(false);
        const viewHour = ref(0);
        const isNewOrder = ref(false);
        const canCreateSlotInCreatingOrder = ref(false);
        const employees = ref<Employee[]>([]);

        const { t, locale } = useI18n();
        const { revertSlot } = useSlots();
        const { removemillis } = useOrder();
        const isRTL = locale.value == 'he-IL';

        const productsPromise = api.getRefProducts();
        const calendarEventsPromise = api.getCalendarEvents();
        const events = ref<CalendarEvent[]>([]);
        const slots = ref<CalendarSlot[]>([]);
        let availabilityDataPromise: Promise<ApiResult<CalendarAvailabilityResponse>> = new Promise((yes, no) =>
            yes(new ApiResult<CalendarAvailabilityResponse>(null, undefined))
        );

        const canCreateChangeOrderInPast = generalStore.getters.canCreateChangeOrderInPast;
        const canCreateChangeSlotInPast = generalStore.getters.canCreateChangeSlotInPast;
        const assignedEmployees = ref<AssignedEmployee[]>([]);
        const tooltipSlotInfo = ref(new TooltipSlotInfo());
        const showCalendar = ref(true);
        const isMobileMode = ref(false);
        const selectedDate = ref(new Date());
        const kendoSheduler = ref<kendo.ui.Scheduler>({} as any);
        const kendoCalendar = ref<kendo.ui.Calendar>({} as any);
        const localDataSource = ref<CalendarSlotKendo[]>([]);
        const products = ref<ProductListItem[]>([]);
        const notActiveProducts = ref<IdName[]>([]);
        const selectedProducts = ref<string[]>([]);
        const showEvents = ref(true);
        const orderPriceTypeId = ref('');
        const priceTypes = ref<PriceType[]>([]);
        const productsSlotModal = computed(() => (props.changeDate ? products.value.filter(x => x.id == props.product) : products.value));
        const dataSource = new kendo.data.SchedulerDataSource({
            data: [],
            schema: {
                model: {
                    id: 'id',
                    fields: {
                        id: { from: 'id' },
                        title: { from: 'title', defaultValue: '', validation: { required: false } },
                        start: { type: 'date', from: 'start' },
                        end: { type: 'date', from: 'end' },
                        limit: { from: 'limit' },
                        recurrenceId: { from: 'originalSlotId' },
                        recurrenceRule: { from: 'recurrenceRule' },
                        recurrenceException: { from: 'recurrenceException' },
                        type: { from: 'type', defaultValue: 'slot' },
                        productId: { from: 'productId', defaultValue: '' },
                        isAllDay: { type: 'boolean', from: 'isAllDay' }
                    }
                }
            }
        });
        const { getKendoRange, findOccurence, findSlotInfo, updateVirtualSlots, mergeAvailability } = useSchedulerExtensions(
            kendoSheduler,
            dataSource
        );
        const selectedDateData = computed(() => {
            return {
                day: kendo.toString(selectedDate.value, 'ddd'),
                date: kendo.toString(selectedDate.value, 'dd/MM/yyyy')
            };
        });
        const canMoveOrders = ref(false);
        const isSoldOut = ref(false);

        function onFilter(selectAll?: boolean) {
            if (selectAll === true) {
                selectedProducts.value = products.value.map(x => x.id);
                if (router.currentRoute.value.query.product) {
                    router.replace({
                        query: {}
                    });
                }
            } else if (selectAll === false) {
                selectedProducts.value = [];
            }
            refreshAvailability = true;
            dataSource.filter({
                operator: function(task: CalendarSchedulerEvent) {
                    if (task.type == 'event') {
                        return showEvents.value;
                    }
                    if (!selectedProducts.value.includes(task.productId)) return false;
                    return true;
                }
            });
        }

        async function loadAvailability(from = '', to = '') {
            if (from && to) {
                availabilityDataPromise = api.getCalendarAvailabilityV3(from, to, props.product ?? null, props.removePastSlots);
            }
            const v = getKendoRange();
            const startDate = moment(from || v.start).toDate();
            const endDate = moment(to || v.end)
                .endOf('day')
                .toDate();
            const response = await availabilityDataPromise;
            if (response?.errorMessage || !response?.data) {
                swal.fire({
                    icon: 'error',
                    text: response.errorMessage || 'error'
                });
                return false;
            }
            mergeAvailability(response.data.availability, startDate, endDate);
            return true;
        }

        function reloadSlotAvailability() {
            const v = getKendoRange();
            loadAvailability(moment(v.start).format('YYYY-MM-DD'), moment(v.end).format('YYYY-MM-DD'));
        }

        async function reloadSlots() {
            const response = await api.getCalendarV3(props.product, props.selectedSlotId, props.removePastSlots, props.changeDate);
            if (response.errorMessage || !response.data) {
                swal.fire({
                    icon: 'error',
                    text: response.errorMessage || 'error'
                });
                return false;
            }
            slots.value = response.data.slots;
            return true;
        }

        async function loadSlots(reload = false, reloadAvailability = true) {
            if (reload) {
                const loaded = await reloadSlots();
                if (!loaded) return;
            }
            const slotsList = slots.value.map(x => CalendarSlotKendo.fromSlot(x));
            const eventsList = events.value.map(x => CalendarSlotKendo.fromEvent(x));
            dataSource.data([...slotsList, ...eventsList]);
            dataSource.sync();
            if (reloadAvailability) {
                if (kendoSheduler.value.view()) {
                    reloadSlotAvailability();
                } else {
                    kendoSheduler.value.trigger('navigate');
                }
            } else {
                updateVirtualSlots();
            }
        }
        function selectSlotForOrder(model: CalendarSlot) {
            const slot: Slot = {
                duration: removemillis(model.duration),
                endTime: removemillis(model.endTime),
                id: model.id,
                quantity: 0,
                startTime: removemillis(model.startTime),
                endTimeView: removemillis(model.endTime),
                startTimeView: removemillis(model.startTime),
                isVirtual: model.isVirtual
            };
            const slotId = slot.id;
            const startDate = model.startDate;
            const ignoreQty = true;
            emit('onselectslot', { slotId, slot, date: startDate, ignoreQty });
        }

        async function onSlotChanged(model: CalendarSlot) {
            if (props.changeDate && props.editExistOrder && model.connection != null) {
                emit('openOrder');
                return;
            }
            if (props.changeDate) {
                selectSlotForOrder(model);
                return;
            }
            await loadSlots(true);
            await loadAvailability();
        }

        function onEventChanged(apiEvent: CalendarEvent) {
            const event = CalendarSlotKendo.fromEvent(apiEvent);
            // update data
            {
                const index = events.value.findIndex(x => x.id == event.id);
                if (index >= 0) {
                    events.value.splice(index, 1, apiEvent);
                } else {
                    events.value.push(apiEvent);
                }
            }
            // update scheduler
            {
                const allEvents = dataSource.data().map(x => x as CalendarSchedulerEvent);
                const index = allEvents.findIndex(x => x.apiEvent && x.id == event.id);
                if (index >= 0) {
                    allEvents.splice(index, 1, event);
                } else {
                    allEvents.push(event);
                }
                dataSource.data(allEvents);
            }

            dataSource.sync();
        }

        function onSlotStopCreated(stop: CalendarStopSale) {
            const slot = slots.value.find(x => x.id == stop.slotId);
            if (slot) {
                slot.repeatStops.unshift(stop);
                loadSlots(false, false);
            }
        }

        function onSlotStopRemoved(stop: CalendarStopSale) {
            const slot = slots.value.find(x => x.id == stop.slotId);
            if (slot) {
                slot.repeatStops = slot.repeatStops.filter(s => s.id != stop.id);
                loadSlots(false, false);
            }
        }

        function onSlotRemoved(slotId: string) {
            const i = slots.value.findIndex(x => x.id == slotId);
            if (i >= 0) {
                slots.value.splice(i, 1);
                loadSlots(false, false);
            }
        }

        function onSlotReverted(slot: CalendarSlot) {
            const i = slots.value.findIndex(x => x.id == slot.id);
            if (i >= 0) {
                slots.value.splice(i, 1);
                if (slot.originalSlotId) {
                    const parent = slots.value.find(x => x.id == slot.originalSlotId);
                    if (parent) {
                        parent.repeatStops = parent.repeatStops.filter(x => !(x.isChange && x.startDate == slot.startDate));
                    }
                }
                loadSlots(false, false);
            }
        }

        async function openSlotEmployees(slotItem: CalendarSlot, date: string, isViewMode: boolean) {
            const reqSlot = slots.value.find(x => x.id == slotItem.id);
            if (!reqSlot) {
                return;
            }
            swal.showLoading();
            const apiPromise = api.getEmployeesForSlot(slotItem.id, date);
            await Q.delay(400);
            const resp = await apiPromise;
            if (!resp.data) {
                return;
            }
            const availableBySlot = resp.data.availableEmployeeIds ?? [];
            employees.value = resp.data.employees;
            const assignedEmployeeIds = resp.data.assignedEmployeeIds;

            const productName = products.value.find(x => x.id == reqSlot.productId)?.name || '';
            modalSlotEmployees.value?.open(reqSlot, productName, employees.value, availableBySlot, date, assignedEmployeeIds, isViewMode);
            swal.close();
        }

        async function openSmsEmailModal(slotItem: CalendarSlot, date: Date) {
            modalSaveSendNotificationSmsEmailTemplate.value?.open(null, slotItem.id, date, slotItem.productId);
        }

        const eventActions: { [key: string]: Function } = {
            edit: function(event: CalendarEvent) {
                modal.value?.openEvent(event);
            },
            delete: async function(event: CalendarEvent) {
                const confirmDelete = await swal.fire({
                    icon: 'info',
                    title: t('calendar.v3.warning.confirm_remove'),
                    customClass: {
                        confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                        denyButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                    },
                    buttonsStyling: false,
                    showDenyButton: true,
                    showCloseButton: true,
                    confirmButtonText: t('alert.yesDeleteIt'),
                    denyButtonText: t('button.cancel')
                });
                if (!confirmDelete.isConfirmed) return;
                await Q.delay(150);

                swal.showLoading();
                const resp = await api.deleteCalendarEvent(event.id);
                if (resp.errorMessage || !resp.data) {
                    swal.fire({
                        title: resp.errorMessage || 'Error',
                        icon: 'error'
                    });
                    return;
                }
                swal.fire({ icon: 'success' });
                // update data
                {
                    const index = events.value.findIndex(x => x.id == event.id);
                    if (index >= 0) {
                        events.value.splice(index, 1);
                    }
                }
                // update scheduler
                {
                    const allEvents = dataSource.data().map(x => x as CalendarSchedulerEvent);
                    const index = allEvents.findIndex(x => x.apiEvent && x.id == event.id);
                    if (index >= 0) {
                        allEvents.splice(index, 1);
                        dataSource.data(allEvents);
                        dataSource.sync();
                    }
                }
            }
        };

        const actions: { [key: string]: Function } = {
            edit: function(slot: CalendarSlot, uid: string) {
                const item = findOccurence(uid);
                const { available, slotInfo } = findSlotInfo(item);
                if (props.editExistOrder) {
                    if (slot.forGroups) {
                        slot.forGroupsDisabled = true;
                    }
                    if (slot.forIndividuals) {
                        slot.forIndividualsDisabled = true;
                    }
                }

                canMoveOrders.value = slotInfo.sold > 0;
                isSoldOut.value = available === 0 && slotInfo.sold > 0;

                if (isSoldOut.value) {
                    slot.id = slotInfo.slotId;
                    slot.originalSlotId = slotInfo.originalSlotId;
                }

                modal.value?.open(slot, true, props.changeDate, props.editExistOrder);
            },
            /*
            delete: async function(slot: CalendarSlot) {
                const confirmDelete = await swal.fire({
                    icon: 'info',
                    title: t('calendar.v3.warning.confirm_remove'),
                    customClass: {
                        confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                        denyButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                    },
                    buttonsStyling: false,
                    showDenyButton: true,
                    showCloseButton: true,
                    confirmButtonText: t('alert.yesDeleteIt'),
                    denyButtonText: t('button.cancel')
                });
                if (!confirmDelete.isConfirmed) return;

                swal.showLoading();
                const stopDate = slot.originalSlotId ? (slot.startDate as string) : null;
                let resp = await api.deleteCalendarSlot(slot.id || slot.originalSlotId, stopDate);
                if (resp.errorMessage || !resp.data) {
                    if (resp.errorCode == 1) {
                        const swalResult = await swal.fire({
                            icon: 'info',
                            title: t('calendar.v3.warning.has_orders'),
                            customClass: {
                                confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                                denyButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                            },
                            buttonsStyling: false,
                            showDenyButton: true,
                            showCloseButton: true,
                            confirmButtonText: t('alert.yesDeleteIt'),
                            denyButtonText: t('button.cancel')
                        });
                        if (!swalResult.isConfirmed) return;
                        resp = await api.deleteCalendarSlot(slot.id || slot.originalSlotId, stopDate, true);
                        if (resp.errorMessage || !resp.data) {
                            swal.fire({
                                title: resp.errorMessage || 'Error',
                                icon: 'error'
                            });
                            return;
                        }
                    } else {
                        swal.fire({
                            title: resp.errorMessage || 'Error',
                            icon: 'error'
                        });
                        return;
                    }
                }
                swal.fire({ icon: 'success' });
                onSlotChanged();
            },
            */
            orders: async function(slot: CalendarSlot) {
                const filter = new OrdersFilter();
                filter.arrivalStartDate = moment(slot.startDate).format('YYYY-MM-DD');
                filter.arrivalEndDate = filter.arrivalStartDate;
                filter.startTime = moment(slot.startTime, ['H:m']).format('HH:mm:ss');
                filter.productId = slot.productId;
                filter.slotId = slot.originalSlotId || slot.id;
                generalStore.commit('saveOrdersFilter', filter);
                router.push({ name: 'orders', params: { newSearch: '1' } });
            },
            neworder: async function(slot: CalendarSlot) {
                swal.showLoading();
                const response = await api.checkProductPriceLists(slot.productId, slot.startDate);
                if (response.data == false || response.error) {
                    swal.fire({
                        icon: 'error',
                        text: t('calendar.v3.modal.error.priceListNotFound')
                    });
                    return;
                }
                swal.close();
                const canCreateOrders = generalStore.getters.canCreateOrders;
                if (!canCreateOrders) {
                    swal.fire({
                        icon: 'error',
                        text: 'No permission'
                    });
                    return;
                }
                modalOrder.value?.open(slot);
            },
            stopsale: function(slot: CalendarSlot, date: Date) {
                const product = products.value.find(x => x.id == slot.productId);
                if (!product) return;
                modalStopSale.value?.open(slot, product, date);
            },
            revert: function(slot: CalendarSlot) {
                const now = new Date();
                now.setHours(0, 0, 0, 0);
                if (moment(slot.startDate).toDate() < now || !slot.originalSlotId) return;

                swal.fire({
                    icon: 'info',
                    title: t('calendar.v3.slot.confirm-revert'),
                    customClass: {
                        confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                        denyButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                    },
                    buttonsStyling: false,
                    showDenyButton: true,
                    showCloseButton: true,
                    confirmButtonText: t('alert.yesContinue'),
                    denyButtonText: t('button.cancel')
                }).then(async swalResult => {
                    if (!swalResult.isConfirmed) return null;
                    await Q.delay(150);
                    swal.showLoading();
                    const result = await revertSlot(slot.id);
                    if (result) {
                        onSlotReverted(slot);
                        swal.close();
                    }
                });
            },
            history: function(slot: CalendarSlot) {
                const slotsChanges = slots.value.filter(x => x.originalSlotId == slot.id);
                if (slotsChanges.length == 0) return;
                const product = products.value.find(x => x.id == slot.productId);
                if (!product) return;
                modalHistory.value?.open(slot, product, slotsChanges);
            },
            employee: async function(slot: CalendarSlot, date: Date) {
                const startDate = moment(date).format('YYYY-MM-DD');
                await openSlotEmployees(slot, startDate, false);
            },
            historyOfAssignment: async function(slot: CalendarSlot, date: Date) {
                const startDate = moment(date).format('YYYY-MM-DD');
                await openSlotEmployees(slot, startDate, true);
            },
            smsEmail: async function(slot: CalendarSlot, date: Date) {
                await openSmsEmailModal(slot, date);
            }
        };

        async function tooltipAction(actionName: string, uid: string) {
            const item = findOccurence(uid).toJSON() as CalendarSlotKendo;
            const mode: CalendarMode = item.apiEvent ? 'event' : 'slot';

            if (mode == 'slot') {
                const action = actions[actionName];
                if (!action) return;

                // if item.recurrenceRule is not NULL then it's a serie
                let editAll = true;
                if ((item.recurrenceId || item.recurrenceRule) && ['edit', 'delete'].includes(actionName)) {
                    const swalResult = await swal.fire({
                        icon: 'info',
                        title: t('calendar.v3.series-edit.title'),
                        customClass: {
                            confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-3 col-5',
                            denyButton: 'close-button-wrapper popup-bookit-button my-1 px-3 col-5'
                        },
                        buttonsStyling: false,
                        showDenyButton: true,
                        showCloseButton: true,
                        confirmButtonText: t('calendar.v3.series-edit.btn.all'),
                        denyButtonText: t('calendar.v3.series-edit.btn.single')
                    });
                    if (swalResult.isDismissed) return;
                    editAll = swalResult.isConfirmed;
                }
                if (actionName == 'orders') {
                    editAll = false;
                } else if (actionName == 'neworder') {
                    editAll = false;
                } else {
                    item.slotId = '';
                }

                const slot = CalendarSlot.fromKendoSlot(item, editAll);
                if (actionName == 'edit') {
                    action(slot, uid);
                } else {
                    action(slot, item.start);
                }
            } else {
                const action = eventActions[actionName];
                if (!action) return;

                const slot = CalendarEvent.fromKendoSlot(item);
                action(slot);
            }
        }

        function getAvailableRowTextInSlotTemplate(
            available: number | null,
            slotInfo: any,
            item: CalendarSlotKendo & kendo.data.SchedulerEvent
        ) {
            if (available === 0 && slotInfo.sold > 0) {
                return '<div class="mb-1"><i class="icon-f icon-sold fs-18"></i></div>';
            }
            if (item.apiSlot?.connection == false) {
                return '';
            }
            return `<div class="item mb-1">
                        <i class="icon-f icon-circle-check fs-20"></i>
                        <span class="counter">
                            ${available == null ? '<i class="icon-f fs-8 icon-infinity"></i>' : available}
                        </span>
                    </div>`;
        }

        const calendarSlotTemplate = function(item: CalendarSlotKendo & kendo.data.SchedulerEvent) {
            const { startTime, slotInfo, available } = findSlotInfo(item);
            const currentProduct = products.value.find(x => x.id == item.productId);
            const productName = currentProduct?.name || '';

            const span = moment(item.end).diff(item.start, 'minutes');
            let availabilityBlock = '';
            let virtualSlotWithCustomerInfoClass = '';
            let style = '';
            if (props.changeDate && props.selectedDateProp && props.selectedSlotId) {
                const dataArray = props.selectedDateProp.split('T');
                const date = dataArray[0].replaceAll('-', '');
                const dateTime = dataArray[1].replaceAll(':', '');
                if (startTime == `${date}_${dateTime}` && item.apiSlot?.id == props.selectedSlotId) {
                    style = "style='background-color: green;'";
                }
            }
            if (!(item as any).tail && span > 40) {
                const startDate = moment(item.start)
                    .format('YYYY-MM-DD')
                    .toString();
                const assignedEmployeeIcon =
                    assignedEmployees.value.filter(x => x.slotId === item.apiSlot?.id && x.startDate === startDate).length > 0
                        ? `<div class="item mb-1 assigned-employee-div">
                                <div class="assigned-employee-icon"></div>
                            </div>`
                        : `<div class="item mb-1 assigned-employee-div d-none"><div class="assigned-employee-icon"></div></div>`;

                const availableIcon = getAvailableRowTextInSlotTemplate(available, slotInfo, item);
                const virtualSlotTextInfo =
                    item.isVirtual || item.apiSlot?.isVirtual
                        ? `<div class="w-100 text-center">${t('calendar.v3.modal.limit.order-qty')}</div>`
                        : '';

                availabilityBlock = `
                        <div class="slot-info">
                            ${assignedEmployeeIcon}
                            ${virtualSlotTextInfo}
                            ${availableIcon}
                            <div class="item">
                                ${item.apiSlot?.connection == false ? '' : "<i class='icon-f icon-orders fs-20'></i>"}
                                <span class="counter">${
                                    item.apiSlot?.connection != null ? item.apiSlot?.limit || '' : slotInfo.sold || 0
                                }</span>
                            </div>
                        </div>`;
            }
            if (item.apiSlot && item.apiSlot?.connection == true && (item.apiSlot.customerName || item.apiSlot.customerGroupName)) {
                virtualSlotWithCustomerInfoClass = 'virtual-slot-with-customer-info';
            }
            if (available === 0 && slotInfo.sold > 0) {
                style = `style='background-color: white; border: 3px Solid ${currentProduct?.color}; color: #5d6675;'`;
            }

            return `<div ${style} class="event-slot-template ${virtualSlotWithCustomerInfoClass}" data-id="${item.apiSlot?.id ??
                slotInfo.slotId}" data-date="${startTime}">
                        <div class="title single-ellipsis" title="${productName}">
                            <span>${productName}</span>
                        </div>
                        <div class="title">
                            <span>${window.kendo.toString(item.start, 'HH:mm')} - ${window.kendo.toString(item.end, 'HH:mm')}</span>
                        </div>
                        ${availabilityBlock}
                    </div>`;
        };

        const calendarEventTemplate = function(item: CalendarSlotKendo & kendo.data.SchedulerEvent) {
            const event = item.apiEvent;
            if (!event) return '';

            return `<div class="event-slot-template event-template" data-id="${item.id}">
                        <div class="title">
                            <span>${window.kendo.toString(item.start, 'HH:mm')} - ${window.kendo.toString(item.end, 'HH:mm')}</span>
                        </div>
                        <div class="title text-break">
                            <big>${event.name}</big>
                        </div>
                    </div>`;
        };

        const eventTemplate = function(item: CalendarSlotKendo & kendo.data.SchedulerEvent) {
            if (item.apiEvent) {
                return calendarEventTemplate(item);
            } else {
                return calendarSlotTemplate(item);
            }
        };

        function getAvailableRowTextInTooltip(available: number | null, slotInfo: any, item: CalendarSchedulerEvent) {
            if (available === 0 && slotInfo.sold > 0) return '<div><i class="icon-f icon-sold fs-18"></i></div>';
            if (item.apiSlot?.connection != false)
                return `<i class="icon-f icon-circle-check fs-20"></i>
                    <span class="mx-2">
                    ${available == null ? '<i class="icon-f fs-9 icon-infinity"></i>' : available}
                    </span>
                    <span>${t('calendar.tooltip.available')}</span>`;
            return '';
        }

        function tooltipSlotTemplate(uid: string, item: CalendarSchedulerEvent) {
            const { slotInfo, available } = findSlotInfo(item);
            const now = new Date();
            now.setHours(0, 0, 0, 0);
            let productName = products.value.find(x => x.id == item.productId)?.name;
            if (!productName) {
                productName = notActiveProducts.value.find(x => x.id == item.productId)?.name;
            }
            const availableIcon = getAvailableRowTextInTooltip(available, slotInfo, item);
            const virtualSlotText = item.isVirtual || item.apiSlot?.isVirtual ? t('calendar.v3.modal.limit.order-qty') : '';

            const orderClass = slotInfo.productId || item.apiSlot?.connection || slotInfo.sold != 0 ? '' : ' disabled ';
            const isVirtualSlot = item.isVirtual || item.apiSlot?.isVirtual ? ' disabled' : '';
            const neworderClass =
                (item.start < new Date() && !canCreateChangeOrderInPast) || !item.apiSlot?.id || item.apiSlot?.isVirtual
                    ? ' disabled '
                    : '';
            const isSeparateSlot = item.apiSlot?.originalSlotId;
            const datePassedNotSeria =
                !item.apiSlot ||
                item.apiSlot?.connection == true ||
                (item.apiSlot.repeatMode == 1 && moment(item.apiSlot.startDate).toDate() < now);
            const stopsaleClass = datePassedNotSeria ? ' disabled ' : ''; //isSeparateSlot ? ' disabled ' : '';
            const assignEmployeesClass =
                item.apiSlot?.allowAssignEmployees === false || item.start < new Date() || !item.apiSlot?.id ? ' d-none ' : '';
            const viewHistoryOfAssignmentEmployees =
                item.start < new Date() &&
                assignedEmployees.value.find(x => x.slotId == item.apiSlot?.id && x.startDate == moment(item.start).format('YYYY-MM-DD'))
                    ? ''
                    : 'd-none';
            const hasChildSlots = item.apiSlot?.repeatStops?.find(s => s.isChange) ? true : false;
            let historyClass = !datePassedNotSeria || hasChildSlots ? '' : ' disabled ';
            const revertHistoryAction = isSeparateSlot ? 'revert' : 'history';
            if (!isSeparateSlot) {
                const hasHistory = item.apiSlot && slots.value.filter(x => x.originalSlotId == item.apiSlot?.id).length > 0;
                if (!hasHistory) {
                    historyClass = ' disabled ';
                }
            }

            const buttonsExcludedEdit = `
                <span class="event-icon-text ${orderClass}" ${orderClass} data-action="orders">
                    <i class="icon-f icon-orders fs-20 p-1"></i>
                    <span>${t('calendar.tooltip.orders')}</span>
                </span>
                <span class="event-icon-text ${neworderClass}" ${neworderClass} data-action="neworder">
                    <i class="icon-f icon-shop-add fs-20 p-1"></i>
                    <span>${t('calendar.tooltip.neworder')}</span>
                </span>
                <span class="event-icon-text ${stopsaleClass}" ${stopsaleClass} data-action="stopsale">
                    <i class="icon-f icon-action-cancel fs-20 p-1"></i>
                    <span>${t('calendar.tooltip.stopsale')}</span>
                </span>
                <span class="event-icon-text ${historyClass}" ${historyClass} data-action="${revertHistoryAction}">
                    <i class="icon-f icon-history fs-20 p-1"></i>
                    <span>${t('calendar.tooltip.' + revertHistoryAction)}</span>
                </span>
                <span class="event-icon-text ${assignEmployeesClass}" data-action="employee">
                    <i class="icon-f icon-employee fs-20 p-1"></i>
                    <span>${t('calendar.tooltip.employee')}</span>
                </span>
                <span class="event-icon-text ${viewHistoryOfAssignmentEmployees}" data-action="historyOfAssignment">
                    <i class="icon-f icon-employee fs-20 p-1"></i>
                    <span>${t('calendar.tooltip.history-of-assignment')}</span>
                </span>
                <span class="event-icon-text" data-action="smsEmail">
                    <i class="icon-f icon-telegram fs-20 p-1"></i>
                    <span>${t('sms-email-management.sms.label')}</span>
                </span>
                `;
            return `

            <div class="tooltip-buttons" style="min-width: 186px;" data-uid="${uid}">
                <span class="event-icon-text${isVirtualSlot} ${props.editExistOrder ? ' event-icon-text-start' : ''}" data-action="edit">
                    <i class="icon-f icon-pencil-edit fs-20 p-1"></i>
                    <span>${t('calendar.tooltip.edit')}</span>
                </span>
                ${props.editExistOrder ? '' : buttonsExcludedEdit}
                <i class="icon-f icon-delete-cross fs-10 p-end-1 close-slot-details-btn"></i>
            </div>
            <div class="pop-up-product-name">${productName}</div>
            <div class="slot-info my-2" style="font-size: 14px">
                <div class="d-flex font-bold" style="color: #9CD330">
                    ${virtualSlotText}
                </div>

                <div class="mt-2 d-flex font-bold" style="color: #9CD330">
                    ${availableIcon}
                </div>

                <div class="mt-2 d-flex font-bold" style="color: #FD7465">
                    <i class="icon-f icon-orders fs-20"></i>
                    <span class="mx-2">${item.apiSlot?.connection ? item.apiSlot?.limit : slotInfo.sold || 0}</span>
                    <span>${t('calendar.tooltip.sold')}</span>
                </div>
            </div>
            `;
        }

        function tooltipEventTemplate(uid: string, item: CalendarSchedulerEvent, event: CalendarEvent) {
            return `

            <div class="tooltip-buttons" data-uid="${uid}">
                <span class="event-icon-text" data-action="edit">
                    <i class="icon-f icon-pencil-edit fs-20 p-1"></i>
                    <span>${t('calendar.tooltip.edit')}</span>
                </span>
                <span class="event-icon-text" data-action="delete">
                    <i class="icon-f icon-trash fs-20 p-1"></i>
                    <span>${t('calendar.tooltip.delete')}</span>
                </span>
                <span class="event-icon-text disabled"></span>
                <span class="event-icon-text disabled"></span>
                <span class="event-icon-text disabled"></span>
                <i class="icon-f icon-delete-cross fs-10 p-end-1 c-grey close-slot-details-btn"></i>
            </div>
            <div class="slot-info" style="font-size: 14px">
                <big class="text-break ellipsis-3 title my-2">${event.name}</big>
            </div>
            `;
        }

        function tooltip(uid: string) {
            if (!uid) return '';
            const item = findOccurence(uid);
            if (item.apiEvent) {
                return tooltipEventTemplate(uid, item, item.apiEvent);
            } else {
                return tooltipSlotTemplate(uid, item);
            }
        }

        function tooltipСustomerInfo(uid: string) {
            if (!uid) return '';
            const item = findOccurence(uid);
            const slot = item.apiSlot!;

            return `
                <div style="min-width: 186px;" data-uid="${uid}">
                    <span  class='tooltip-text'>${slot.customerGroupName} ${slot.customerName}</span>
                </div>
            `;
        }

        function scrollToHour(hour = 8, repeatCount = 10) {
            const sheduler = kendoSheduler.value;
            const contentDiv = sheduler.element.find('div.k-scheduler-content');
            const row = sheduler.element.find('div.k-scheduler-times tr:nth-child(' + (hour * 2 + 1) + ')');

            if (row.length) {
                contentDiv.scrollTop(0);

                const elementTop = row.offset()?.top || 0;
                const containerTop = contentDiv.offset()?.top || 0;

                contentDiv.scrollTop(elementTop - containerTop);
            } else if (repeatCount > 0) {
                Q.delay(25).then(() => {
                    scrollToHour(hour, repeatCount - 1);
                });
                return;
            }
        }

        let lastDatesRange = '';

        function calculateViewHour() {
            const sheduler = kendoSheduler.value;
            const contentDiv = sheduler.element.find('div.k-scheduler-content');
            if (contentDiv.length) {
                const hour = contentDiv[0].scrollTop / 74;
                viewHour.value = Math.floor(hour);
                wasCalcViewHour.value = true;
            } else {
                viewHour.value = 0;
            }
        }

        async function onChangeView(e: kendo.ui.SchedulerNavigateEvent) {
            if (!wasCalcViewHour.value) {
                calculateViewHour();
            }
            wasCalcViewHour.value = false;
            kendo.ui.progress(e.sender.element, true);
            setTimeout(() => {
                selectedDate.value = e.sender.date();
                const range = getKendoRange();
                const start = moment(range.start);
                const end = moment(range.end);
                const filter = {
                    from: start.format('YYYY-MM-DD'),
                    to: end.format('YYYY-MM-DD')
                };
                const datesRange = filter.from + '|' + filter.to;
                if (lastDatesRange == datesRange) {
                    kendo.ui.progress(e.sender.element, false);
                    return;
                }
                kendoCalendar.value.value(kendoSheduler.value.date());
                lastDatesRange = datesRange;

                if (router.currentRoute.value.name) {
                    const parms = JSON.parse(JSON.stringify(router.currentRoute.value.params));
                    parms.date = moment(e.sender.date()).format('YYYY-MM-DD');
                    parms.view = e.sender.viewName();
                    router.replace({ name: router.currentRoute.value.name, params: parms, query: router.currentRoute.value.query });
                }

                loadAvailability(filter.from, filter.to).then(() => {
                    kendo.ui.progress(e.sender.element, false);
                });
            }, 300);
            nextTick(() => {
                scrollToHour(viewHour.value);
            });
        }

        function openNewSlotModal(slot: CalendarSlot) {
            if (slot.startDate && slot.startDate < moment().format('YYYY-MM-DD') && !canCreateChangeSlotInPast) {
                slot.startDate = '';
            }
            if (props.changeDate) {
                if (mixin.methods.isGroupPrice(orderPriceTypeId.value, priceTypes.value)) {
                    slot.forGroups = true;
                    slot.forGroupsDisabled = true;
                    slot.forIndividuals = false;
                    slot.forIndividualsDisabled = false;
                } else {
                    slot.forGroups = false;
                    slot.forGroupsDisabled = false;
                    slot.forIndividuals = true;
                    slot.forIndividualsDisabled = true;
                }
            }

            modal.value?.open(slot, true, props.changeDate);
        }

        function onCreateSlot(e: kendo.ui.SchedulerSaveEvent) {
            e.preventDefault();
            const slot = new CalendarSlot();
            if (props.changeDate) {
                if (!props.product) {
                    return;
                }
                if (!canCreateSlotInCreatingOrder.value) {
                    return;
                }
                slot.productId = props.product;
            }
            if (props.editExistOrder || props.selectedSlotId) {
                if (!e.event) return;
                if (!props.selectedDateProp) {
                    return;
                }
                const selectedSlot = slots.value.find(x => x.id == props.selectedSlotId);
                if (!selectedSlot) {
                    return;
                }
                const start = moment(e.event.start);
                const minutes = start.minutes() % 15;
                if (minutes > 0) {
                    start.add(minutes > 7 ? 15 - minutes : -minutes, 'minutes');
                }
                slot.id = props.selectedSlotId && selectedSlot.isVirtual ? props.selectedSlotId : '';
                slot.blockCreatingNewOrdersBefore = selectedSlot.blockCreatingNewOrdersBefore;
                slot.connection = selectedSlot.connection;
                slot.daysOfWeek = selectedSlot.daysOfWeek;
                slot.extraInfo = selectedSlot.extraInfo;
                slot.forGroups = selectedSlot.forGroups;
                slot.forIndividuals = selectedSlot.forIndividuals;
                slot.limit = selectedSlot.limit;
                slot.orderBlockMinutes = selectedSlot.orderBlockMinutes;
                slot.repeatMode = 1;
                slot.startDate = start.format('YYYY-MM-DD');
                slot.startTime = start.format('HH:mm');
                slot.endTime = start.add(30, 'minutes').format('HH:mm');
            }
            if (props.editExistOrder) {
                modal.value?.open(slot, false, true, props.editExistOrder);
                return;
            }
            if (!e.event) return;
            const start = moment(e.event.start);
            const minutes = start.minutes() % 15;
            if (minutes > 0) {
                start.add(minutes > 7 ? 15 - minutes : -minutes, 'minutes');
            }
            slot.startDate = start.format('YYYY-MM-DD');
            slot.startTime = start.format('HH:mm');
            slot.endTime = start.add(1, 'hour').format('HH:mm');
            openNewSlotModal(slot);
        }

        function onCreateNewSlot() {
            const slot = new CalendarSlot();
            const start = moment(kendoSheduler.value.date());
            slot.startDate = start.format('YYYY-MM-DD');
            openNewSlotModal(slot);
        }

        async function SendSmsEmail(req: SendEmailSmsAction) {
            const templateResources = toResource(req.template);
            const apiPromise = api.SendSmsEmailBySlot(req.slotId, req.date, templateResources, req.productId);
            await Q.delay(400);
            const apiResult = await apiPromise;
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            let msg = '';
            let totalCount = 0;
            if (req.template.sendSms) {
                const smsCount = apiResult.data?.smsCount ?? 0;
                totalCount += smsCount;

                msg += '<p class="mb-8">' + t('modal.sendmessage.smscount', { count: smsCount }) + '</p>';
            }
            if (req.template.sendEmail) {
                const emailCount = apiResult.data?.emailCount ?? 0;
                totalCount += emailCount;

                msg += '<p class="mb-8">' + t('modal.sendmessage.emailcount', { count: emailCount }) + '</p>';
            }
            if (totalCount == 0) {
                swal.fire({
                    icon: 'info',
                    text: 'No receipts'
                });
                return;
            }
            const swalResult = await swal.fire({
                icon: 'info',
                title: t('modal.sendmessage.continue') + '?',
                html: msg,
                customClass: {
                    confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: t('button.send'),
                cancelButtonText: t('send-sms-email.cancel')
            });
            if (swalResult.isConfirmed) {
                await Q.delay(200);
                const apiPromise2 = api.SendSmsEmailBySlot(req.slotId, req.date, templateResources, req.productId, true);
                await Q.delay(400);
                const apiResult2 = await apiPromise2;
                if (apiResult2.errorMessage) {
                    swal.fire({
                        icon: 'error',
                        text: apiResult.errorMessage
                    });
                    return;
                }
                swal.fire({
                    icon: 'success',
                    text: t('modal.sendmessage.msgsent')
                });
                modalSaveSendNotificationSmsEmailTemplate.value?.close();
            }
        }

        function navigateDate(dayShift: number) {
            calculateViewHour();
            kendoSheduler.value.date(
                moment(kendoSheduler.value.date())
                    .add({ days: dayShift })
                    .toDate()
            );
            kendoSheduler.value.trigger('navigate');
        }

        let lastHeight = 0;

        function getSchedullerHeight() {
            isMobileMode.value = window.innerWidth <= 768;
            return Math.max(window.innerHeight - headerHeight - 2 - (isMobileMode.value ? 55 : 0), 200);
        }

        function onResize() {
            const h = getSchedullerHeight();
            if (h == lastHeight) return;
            lastHeight = h;
            kendoSheduler.value.element.height(h);
            kendoSheduler.value.resize(true);
        }

        function assignEmployee(req: AssignEmployeeRequest) {
            const slotId = req.slotId;
            const employeeId = req.employeeId;
            const slotItem = slots.value.find(x => x.id == slotId);
            if (slotItem) {
                slotItem.employeeIds.push(employeeId);
            }
        }
        function unassignEmployee(req: AssignEmployeeRequest) {
            const slotId = req.slotId;
            const employeeId = req.employeeId;
            const slotItem = slots.value.find(x => x.id == slotId);
            if (slotItem) {
                slotItem.employeeIds = slotItem.employeeIds.filter(x => x != employeeId);
            }
        }

        async function changeEmployeeIcon(req: ChangeSlotEmployeeIcon) {
            const startDate = req.startDate.replaceAll('-', '');
            const foundedSlot = slots.value.find(x => x.id === req.slotId);
            if (!foundedSlot) {
                return;
            }
            const splitedStartTime = foundedSlot.startTime.split(':');
            const startTime = splitedStartTime[0] + splitedStartTime[1];
            const queryData = startDate + '_' + startTime;
            const el = document.querySelector(`[data-id="${req.slotId}"][data-date="${queryData}"]`);
            if (!el) {
                return;
            }
            const employeeIcon = el.getElementsByClassName('assigned-employee-div')[0];
            if (req.remove) {
                employeeIcon.classList.add('d-none');
            } else {
                employeeIcon.classList.remove('d-none');
            }

            const response = await api.getAssignedEmployees();
            if (response.data && response.data.assignedEmployees) {
                assignedEmployees.value = response.data.assignedEmployees;
            }
        }

        onUnmounted(() => {
            window.removeEventListener('resize', onResize);
        });

        onMounted(async () => {
            window.addEventListener('resize', onResize);
            const schedulerHeight = getSchedullerHeight();
            orderPriceTypeId.value = generalStore.getters.getNewOrderPriceTypeId;

            selectedDate.value = new Date();
            if (props.date) {
                selectedDate.value = moment(props.date).toDate();
            }
            const storeOrder = generalStore.getters.getNewOrder;
            isNewOrder.value = storeOrder == null || storeOrder.id == '';
            let selectedView = (props.view || 'week').toLowerCase();
            if (!['day', 'week'].includes(selectedView)) {
                selectedView = 'week';
            }
            if (isMobileMode.value) {
                selectedView = 'day';
                showCalendar.value = false;
            }
            const calendarKendo = calendarElement.value;
            const wrapperKendo = kendoWrapper.value;
            if (!wrapperKendo || !calendarKendo) {
                console.log('Error creating calendaer');
                return;
            }
            const result = (await productsPromise).data;
            products.value = _.orderBy(result?.products || [], x => x.name);
            const product = products.value.find(x => x.id == props.product);
            if (product && product.availableProductPriceTypes.includes(orderPriceTypeId.value)) {
                canCreateSlotInCreatingOrder.value = true;
            }
            notActiveProducts.value = result?.notActiveProducts || [];
            selectedProducts.value = products.value.map(x => x.id);
            const filterProduct = props.product || router.currentRoute.value.query.product;
            if (filterProduct) {
                selectedProducts.value = selectedProducts.value.filter(x => x == filterProduct);
                showEvents.value = false;
                onFilter();
            }
            const productResources = products.value.map(x => ({
                value: x.id,
                text: x.name,
                color: props.changeDate ? '#297099' : x.color
            }));
            let isCalendarDragging = false;
            priceTypes.value = (await generalStore.dispatch('getPriceTypes')) as PriceType[];

            // create Calendar from div HTML element
            kendoCalendar.value = kendoQuery(calendarKendo)
                .on('click.kendoCalendar touchend', 'td:has(.k-link)', function(e) {
                    if (isCalendarDragging) {
                        e.stopImmediatePropagation();
                        e.preventDefault();
                    }
                })
                .kendoCalendar({
                    footer: '',
                    animation: {
                        horizontal: {
                            reverse: !isRTL
                        }
                    },
                    change: event => {
                        calculateViewHour();
                        const val = event.sender.value();
                        if (kendoSheduler.value.date() != val) {
                            kendoSheduler.value.date(val);
                            selectedDate.value = val;
                            kendoSheduler.value.trigger('navigate');
                        }
                        if (isMobileMode.value) {
                            showCalendar.value = false;
                        }
                    }
                } as CalendarOptions)
                .on('click', '.k-calendar-td.k-state-selected', function() {
                    if (isMobileMode.value) {
                        showCalendar.value = false;
                    }
                })
                .data('kendoCalendar');

            kendoQuery(calendarKendo).kendoTouch({
                enableSwipe: true,
                swipe: function(e) {
                    if (!['left', 'right'].includes(e.direction || '')) return;
                    const goNext = isRTL ? e.direction == 'right' : e.direction == 'left';

                    goNext ? kendoCalendar.value.navigateToFuture() : kendoCalendar.value.navigateToPast();
                }
            });

            kendoQuery(calendarKendo)
                .find('.k-calendar-view')
                .kendoTouch({
                    enableSwipe: false,
                    dragstart() {
                        isCalendarDragging = true;
                    },
                    dragend() {
                        Q.delay(150).then(() => {
                            isCalendarDragging = false;
                        });
                    }
                });

            kendoSheduler.value = kendoQuery(wrapperKendo)
                .kendoScheduler({
                    dateHeaderTemplate: kendo.template(`
                    <div class="k-link k-nav-day k-date-wrapper #=kendo.date.isToday(date)?'current':''#">
                        <div class="k-date-weekday">#=kendo.toString(date, 'ddd')#</div>
                        <div class="k-date-day">#=kendo.toString(date, ' d').trim()#</div>
                    </div>`),
                    date: selectedDate.value,
                    majorTimeHeaderTemplate: kendo.template(`<span class="k-time">#=kendo.toString(date, 'HH')#.00</span>`),
                    allDaySlot: false,
                    startTime: new Date('2013/6/13 00:00 AM'),
                    height: schedulerHeight,
                    views: [
                        {
                            type: 'day',
                            title: t('calendar.v3.view.day'),
                            selected: selectedView == 'day',
                            selectedDateFormat: '{1:dd/MM/yyyy}'
                        },
                        {
                            type: 'week',
                            title: t('calendar.v3.view.week'),
                            selected: selectedView == 'week',
                            selectedDateFormat: '{1:MMMM yyyy}'
                        }
                    ],
                    workDayStart: new Date('2000/1/1 00:00'),
                    workDayEnd: new Date('2000/1/1 23:59'),
                    footer: false,
                    workWeekStart: 1,
                    workWeekEnd: 7,
                    messages: {
                        today: t('calendar.v3.view.today')
                    },
                    dataBound: () => {
                        if (refreshAvailability) {
                            refreshAvailability = false;
                            nextTick(async () => {
                                updateVirtualSlots();
                            });
                        }
                    },
                    //timezone: 'Etc/UTC',
                    dataSource,
                    navigate: onChangeView,
                    eventTemplate: eventTemplate,
                    resources: [
                        {
                            field: 'productId',
                            title: 'productId',
                            dataSource: productResources
                        },
                        {
                            field: 'type',
                            title: 'type',
                            dataSource: [
                                {
                                    value: 'slot',
                                    text: 'Slot',
                                    color: '#00c8e0'
                                },
                                {
                                    value: 'event',
                                    text: 'Event',
                                    color: '#D9DCE2'
                                }
                            ]
                        }
                    ],
                    add: onCreateSlot,

                    editable: {
                        create: true,
                        confirmation: false,
                        destroy: false,
                        move: false,
                        resize: false,
                        update: false,
                        window: false
                    }
                })
                .on('click', '.k-scheduler-layout', function() {
                    if (isMobileMode.value && showCalendar.value) showCalendar.value = false;
                })
                .data('kendoScheduler') as kendo.ui.Scheduler;

            const kendoTooltip = kendoQuery(wrapperKendo)
                .kendoTooltip({
                    filter: '.event-slot-template-forced',
                    position: 'top',
                    //width: 300,
                    //height: 150,
                    content: (ev: any) => tooltip(ev.target.parent().attr('data-uid')),
                    callout: false,
                    showOn: 'click',
                    show: function(e) {
                        const content = (e.sender as any).content as JQuery<HTMLElement>;
                        content.parent().addClass('sheduler-tooltip');
                        const btns = content.find('.tooltip-buttons');
                        const uid = btns.data('uid');
                        btns.find('[data-action]')
                            .off('click')
                            .on('click', function() {
                                const actionName = this.dataset['action'];
                                if (!actionName) return;
                                kendoTooltip.hide();
                                tooltipAction(actionName, uid);
                            });
                    }
                })
                .data('kendoTooltip');

            const kendoTooltipGroup = kendoQuery(wrapperKendo)
                .kendoTooltip({
                    filter: '.virtual-slot-with-customer-info',
                    position: 'top',
                    showOn: 'mouseenter',
                    content: (ev: any) => tooltipСustomerInfo(ev.target.parent().attr('data-uid')),
                    show: function(e) {
                        kendoTooltip.hide();
                        const content = (e.sender as any).content as JQuery<HTMLElement>;
                        content.parent().addClass('sheduler-tooltip-customer-info');
                    }
                })
                .data('kendoTooltip');

            kendoQuery(wrapperKendo).on('click', '.event-slot-template', function() {
                const el = kendoQuery(this);
                kendoTooltipGroup.hide();
                if (props.changeDate) {
                    const uid = el.parent().data('uid');
                    const item = findOccurence(uid).toJSON() as CalendarSlotKendo;
                    const slotCalendar = CalendarSlot.fromKendoSlot(item, false);
                    const slot: Slot = {
                        duration: slotCalendar.duration,
                        endTime: slotCalendar.endTime,
                        id: slotCalendar.id,
                        quantity: 0,
                        startTime: slotCalendar.startTime,
                        endTimeView: removemillis(slotCalendar.endTime),
                        startTimeView: removemillis(slotCalendar.startTime),
                        isVirtual: slotCalendar.isVirtual
                    };

                    if (slot.id == '') {
                        slot.id = slotCalendar.originalSlotId;
                    }
                    const slotId = slot.id;
                    const startDate = slotCalendar.startDate;
                    if (
                        canCreateSlotInCreatingOrder.value &&
                        !isNewOrder.value &&
                        props.selectedSlotId == slotId &&
                        item.apiSlot?.connection != null
                    ) {
                        kendoTooltip.show(el);
                        return;
                    }
                    emit('onselectslot', { slotId, slot, date: startDate });
                    return;
                }
                if (isMobileMode.value) {
                    const uid = el.parent().data('uid');
                    const slot = findOccurence(uid);
                    tooltipSlotInfo.value = {
                        content: tooltip(uid),
                        product: products.value.find(x => x.id == slot.productId)?.name || '',
                        visible: true,
                        startDate: slot.start,
                        startTime: moment(slot.start).format('HH:mm'),
                        endTime: moment(slot.end).format('HH:mm')
                    };
                } else {
                    kendoTooltip.show(el);
                }
            });

            kendoQuery('.mobile.sheduler-tooltip').on('click', '.tooltip-buttons [data-action]', function() {
                const btns = kendoQuery(this).parent('.tooltip-buttons');
                const uid = btns.data('uid');
                const actionName = this.dataset['action'];
                tooltipSlotInfo.value.visible = false;
                if (!actionName) return;
                tooltipAction(actionName, uid);
            });

            kendoQuery('body').on('click', '.close-slot-details-btn', function() {
                if (isMobileMode.value) {
                    tooltipSlotInfo.value.visible = false;
                } else {
                    kendoTooltip.hide();
                }
            });
            const query = _.clone(router.currentRoute.value.query);
            if (query.new) {
                const type = query.new.toString().toLowerCase();
                query.new = '';
                delete query.new;
                router.replace({ query });

                if (type == 'slot') {
                    const slot = new CalendarSlot();
                    slot.productId = query.product?.toString() ?? '';
                    const start = moment();
                    slot.startDate = start.format('YYYY-MM-DD');
                    if (products.value.length > 0) {
                        const foundProduct = products.value.find(x => x.id === slot.productId);
                        slot.extraInfo = foundProduct?.extraInfo as string;
                        slot.orderBlockMinutes = foundProduct?.orderBlockMinutes as number;
                    }
                    modal.value?.open(slot);
                } else if (type == 'event') {
                    const ecent = new CalendarEvent();
                    const start = moment();
                    ecent.startDate = start.format('YYYY-MM-DD');
                    modal.value?.openEvent(ecent);
                }
            }

            await nextTick();
            events.value = (await calendarEventsPromise).data?.events || [];

            const response = await api.getAssignedEmployees();
            if (response.data && response.data.assignedEmployees) {
                assignedEmployees.value = response.data.assignedEmployees;
            }

            loadSlots(true);
            kendoTooltip.show(kendoQuery('<i>init tooltip to compute the height</i>'));
            kendoTooltip.hide();
            nextTick(() => {
                scrollToHour();
            });
            if (props.employeeSlotId) {
                await Q.delay(400);
                const slotToOpen = slots.value.find(x => x.id == props.employeeSlotId);
                if (!slotToOpen) {
                    return;
                }
                await openSlotEmployees(slotToOpen, props.date ?? '', false);
            }
        });

        return {
            isMobileMode,
            selectedDate,
            selectedDateData,
            localDataSource,
            kendoWrapper,
            calendarElement,
            products,
            selectedProducts,
            tooltipSlotInfo,
            onSlotChanged,
            onEventChanged,
            showEvents,
            headerHeight,
            onFilter,
            onCreateNewSlot,
            showCalendar,
            modalOrder,
            modalStopSale,
            modalHistory,
            modalSlotEmployees,
            reloadSlotAvailability,
            navigateDate,
            isRTL,
            onSlotStopCreated,
            onSlotStopRemoved,
            onSlotRemoved,
            onSlotReverted,
            modal,
            notActiveProducts,
            productsSlotModal,
            assignEmployee,
            unassignEmployee,
            changeEmployeeIcon,
            modalSaveSendNotificationSmsEmailTemplate,
            SendSmsEmail,
            slots,
            loadSlots,
            canMoveOrders,
            isSoldOut
        };
    }
});
