
import { defineComponent, watch, ref, PropType } from 'vue';
import { IdText } from '@/models/Interfaces';

export default defineComponent({
    props: {
        modelValue: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        tabs: {
            type: Array as PropType<IdText[]>,
            required: true
        }
    },
    emits: ['change', 'update:modelValue'],
    setup(props, { emit }) {
        const value = ref(props.modelValue);
        const guid = 'tab_' + kendo.guid() + '_';
        watch(
            () => props.modelValue,
            x => {
                console.log({ x });
                value.value = x;
            }
        );
        return {
            guid,
            value
        };
    }
});
