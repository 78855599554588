import { MessageQueueStatus, QueueMessageSource } from '@/models/Enums';
import { SlotsWithNoEmployee } from '@/models/Employee';
import { ApiResponseBase, IdName } from '@/models/Interfaces';
export interface MessageTemplate {
    id: string;
    name: string;
    text: string;
    sendSms: boolean;
    smsSenderName: string;
    sendEmail: boolean;
    emailSenderName: string;
    emailSubject: string;
    isRtl: boolean;

    attachments: MessageTemplateAttachment[];
}
export interface MessageTemplateResource {
    id: string;
    name: string;
    text: string;
    sendSms: boolean;
    smsSenderName: string;
    sendEmail: boolean;
    emailSenderName: string;
    emailSubject: string;
    isRtl: boolean;

    attachments: MessageTemplateAttachment[];
}

export interface MessageTemplateModel {
    id: string;
    name: string;
    text: string;
}

export interface MessageTemplateAttachment {
    id: string;
    name: string;
    path: string;
}

export interface SmsManagement {
    id: string;
    createdAt: string;
    senderName: string;
    messageText: string;
    amount: number;
    amountSent: number;
    status: number;
    source: QueueMessageSource;
}

export interface EmailManagement {
    id: string;
    createdAt: string;
    senderName: string;
    subject: string;
    messageText: string;
    amount: number;
    amountSent: number;
    status: MessageQueueStatus;
    source: QueueMessageSource;
}

export interface SendEmailSmsAction {
    template: MessageTemplate;
    slotId: string;
    productId: string;
    date: string;
}

export interface GetMessageTemplatesResponse extends ApiResponseBase {
    total: number;
    totalFiltered: number;
    rows: MessageTemplateModel[];
    hasNext: boolean;
}
export interface GetMessageTemplateLightListResponse extends ApiResponseBase {
    rows: IdName[];
}

export interface GetEmailMessagesResponse extends ApiResponseBase {
    total: number;
    totalFiltered: number;
    rows: EmailManagement[];
    hasNext: boolean;
}

export interface GetSmsMessagesResponse extends ApiResponseBase {
    total: number;
    totalFiltered: number;
    rows: SmsManagement[];
    hasNext: boolean;
}
export function toResource(template: MessageTemplate) {
    const templateResources: MessageTemplateResource = {
        attachments: template.attachments,
        emailSenderName: template.emailSenderName,
        emailSubject: template.emailSubject,
        isRtl: template.isRtl,
        sendEmail: template.sendEmail,
        sendSms: template.sendSms,
        smsSenderName: template.smsSenderName,
        text: template.text,
        id: template.id,
        name: template.name
    };
    return templateResources;
}
