import _, { Dictionary } from 'lodash';
import moment from 'moment';
import { ref, Ref } from 'vue';
// REQUIRED FOR TYPES (ts)
import { Scheduler } from '@progress/kendo-scheduler-vue-wrapper';
import { CalendarSchedulerEvent, CalendarSlotAvailability, CalendarSlotKendo } from '@/models/Calendar';

export default function(kendoSheduler: Ref<kendo.ui.Scheduler>, dataSource: kendo.data.SchedulerDataSource) {
    const statisticFormatted = ref<CalendarSlotKendo[]>([]);
    const availability = ref<Dictionary<CalendarSlotAvailability>>({});

    function getKendoRange() {
        const v = kendoSheduler.value.view();
        return {
            start: moment(v.startDate ? v.startDate() : new Date())
                .startOf('day')
                .toDate(),
            end: moment(v.endDate ? v.endDate() : new Date())
                .endOf('day')
                .toDate()
        };
    }

    function occurrencesInRange(start: Date, end: Date) {
        return kendoSheduler.value.occurrencesInRange(start, end) as CalendarSchedulerEvent[];
    }
    function findOccurence(uid: string) {
        return (kendoSheduler.value.occurrenceByUid(uid) as any) as CalendarSchedulerEvent;
    }

    function findSlotInfo(item: CalendarSchedulerEvent) {
        const startTime = moment(item.start).format('YYYYMMDD_HHmm');
        const slotInfo = availability.value[item.slotId + '|' + startTime] || {
            sold: 0
        };
        const limit = slotInfo.limit ? item.limit || slotInfo.limit : item.limit;
        const available = limit != null ? limit - slotInfo.sold : null;
        return {
            startTime,
            slotInfo,
            available
        };
    }

    function updateVirtualSlots(start?: Date, end?: Date) {
        const ranges = getKendoRange();
        start = start || ranges.start;
        end = end || ranges.end;
        const occurences = occurrencesInRange(start, end).filter(x => x.apiSlot);

        const virtualSlots = statisticFormatted.value.filter(
            x => !occurences.filter(existing => existing.slotId == x.slotId && existing.start.toISOString() == x.start.toISOString()).length
        );
        // get all non-virtual slots
        const slots = dataSource
            .data()
            .map(x => x as CalendarSchedulerEvent)
            .filter(x => x.apiSlot || x.apiEvent);
        // add virtual slots
        slots.push(...virtualSlots);
        dataSource.data(slots);
        dataSource.sync();
    }
    function mergeAvailability(statistic: CalendarSlotAvailability[], start: Date, end: Date) {
        statisticFormatted.value = statistic.map(x => CalendarSlotKendo.fromAvailability(x));
        const newStat = _.keyBy(statistic, x => x.slotId + '|' + moment(x.date, moment.ISO_8601).format('YYYYMMDD_HHmm'));
        // remove virtual slots statistic
        Object.keys(availability.value)
            .map(x => ({ key: x, date: moment(x.split('|')[1], 'YYYYMMDD_HHmm').toDate() }))
            .filter(x => x.date >= start && x.date <= end)
            .forEach(x => {
                delete availability.value[x.key];
            });
        _.merge(availability.value, newStat);

        updateVirtualSlots(start, end);
    }

    return {
        statisticFormatted,
        availability,
        getKendoRange,
        findSlotInfo,
        findOccurence,
        mergeAvailability,
        updateVirtualSlots,
        occurrencesInRange
    };
}
