
import { defineComponent, watch, ref, PropType } from 'vue';
import { DayOfWeekKendo } from '@/models/Enums';

export default defineComponent({
    props: {
        modelValue: {
            type: Array,
            required: true
        },
        disabledDays: {
            type: Array
        },
        withCheck: {
            type: Boolean,
            default: true
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const weekDayNames = DayOfWeekKendo;
        const value = ref(props.modelValue);
        const guid = 'day_' + kendo.guid() + '_';
        watch(
            () => props.modelValue,
            x => {
                value.value = x;
            }
        );
        return {
            weekDayNames,
            guid,
            value
        };
    }
});
