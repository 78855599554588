
import { defineComponent, PropType, ref, computed } from 'vue';
import CheckBoxInput from '@/components/CheckBoxInput.vue';
import OverlayModal from './OverlayModal.vue';
import { CalendarSlot } from '@/models/Calendar';
import { AssignEmployeeFilter } from '@/models/Interfaces';
import { Employee } from '@/models/Employee';
import swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import Q from 'q';
import { api } from '@/services/Api';
import { AssignEmployeeRequest, ChangeSlotEmployeeIcon } from '@/models/Interfaces';

export default defineComponent({
    props: {
        byOrder: {
            type: Boolean,
            default: false
        }
    },
    components: { OverlayModal, CheckBoxInput },
    async setup(props, { emit }: any) {
        const filter = ref<AssignEmployeeFilter>({
            keywords: '',
            noAssignmentsCurrentMonth: false,
            onlyAvailable: false,
            assignedOrderSlots: false
        });
        const { t } = useI18n();
        const isMobile = window.innerWidth < 992;
        const assignedEmployeeIds = ref<string[]>([]);
        const selectedTab = ref(1);
        const durationName = ref('');
        const isViewMode = ref(false);

        const slot = ref<CalendarSlot>(new CalendarSlot());
        const productName = ref('');
        const isOpen = ref(false);
        const startDate = ref('');
        const employees = ref<Employee[]>([]);
        const availableEmployeeIds = ref<string[]>([]);
        const availableEmployees = computed(() => {
            let filteredEmployees = employees.value.filter(f => !assignedEmployeeIds.value.includes(f.id));
            if (filter.value.keywords.length > 0) {
                const keywordsToLower = filter.value.keywords.toLocaleLowerCase();
                filteredEmployees = filteredEmployees.filter(
                    x =>
                        x.fullName.toLocaleLowerCase().includes(keywordsToLower) ||
                        x.tagNames.some(x => x.toLocaleLowerCase().includes(keywordsToLower))
                );
            }
            if (filter.value.onlyAvailable) {
                filteredEmployees = filteredEmployees.filter(f => availableEmployeeIds.value.includes(f.id));
            }
            if (props.byOrder) {
                if (filter.value.assignedOrderSlots) {
                    filteredEmployees = filteredEmployees.filter(f => f.assignedOrderSlot);
                }
                if (filter.value.noAssignmentsCurrentMonth) {
                    filteredEmployees = filteredEmployees.filter(f => f.noAssignmentsCurrentMonth && f.noAssignmentsCurrentMonthByOrder);
                }
            } else {
                if (filter.value.noAssignmentsCurrentMonth) {
                    filteredEmployees = filteredEmployees.filter(f => f.noAssignmentsCurrentMonth);
                }
            }

            return filteredEmployees;
        });
        const assignedEmployees = computed(() => employees.value.filter(f => assignedEmployeeIds.value.includes(f.id)));

        const back = () => {
            emit('close');
            isOpen.value = false;
            durationName.value = '';
        };
        async function open(
            reqSlot: CalendarSlot,
            productNameProp: string,
            employee: Employee[],
            availableBySlot: string[],
            date: string,
            assignedEmployeeIdsParam: string[],
            isViewModeParam: boolean
        ) {
            isViewMode.value = isViewModeParam;
            slot.value = reqSlot;
            productName.value = productNameProp;
            filter.value = { keywords: '', noAssignmentsCurrentMonth: false, onlyAvailable: true, assignedOrderSlots: false };
            employees.value = employee;
            availableEmployeeIds.value = availableBySlot;
            assignedEmployeeIds.value = assignedEmployeeIdsParam;
            startDate.value = date;
            isOpen.value = true;
        }
        function showAll() {
            filter.value = { keywords: '', noAssignmentsCurrentMonth: false, onlyAvailable: false, assignedOrderSlots: false };
        }
        async function unassignEmployee(employeeId: string) {
            durationName.value = 'list';
            const resultSwal = await swal.fire({
                title: t('alert.unassignEmployee'),
                icon: 'warning',
                customClass: {
                    confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-4',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: t('alert.btn.yes-confirm'),
                cancelButtonText: t('button.cancel')
            });
            if (!resultSwal.isConfirmed) {
                return;
            }
            await Q.delay(150);
            swal.showLoading();
            const req: AssignEmployeeRequest = {
                employeeId: employeeId,
                slotId: '',
                orderId: '',
                startDate: startDate.value
            };
            if (props.byOrder) {
                req.orderId = slot.value.id;
            } else {
                req.slotId = slot.value.id;
            }
            const unassignEmployeePromise = api.unassignEmployee(req);
            await Q.delay(400);
            const resp = await unassignEmployeePromise;
            if (resp.errorMessage) {
                swal.fire({
                    title: resp.errorMessage || 'Error',
                    icon: 'error'
                });
                return;
            }
            swal.close();
            const index = assignedEmployeeIds.value.indexOf(employeeId);
            assignedEmployeeIds.value.splice(index, 1);
            emit('unassignEmployee', req);
            if (assignedEmployeeIds.value.length === 0) {
                const req: ChangeSlotEmployeeIcon = {
                    slotId: slot.value.id,
                    startDate: startDate.value,
                    remove: true
                };
                emit('changeEmployeeIcon', req);
            }
        }
        async function assignEmployee(employeeId: string) {
            durationName.value = 'list';
            swal.showLoading();
            const wasEmployees = assignedEmployeeIds.value.length > 0;
            const req: AssignEmployeeRequest = {
                employeeId: employeeId,
                slotId: '',
                orderId: '',
                startDate: startDate.value
            };
            if (props.byOrder) {
                req.orderId = slot.value.id;
            } else {
                req.slotId = slot.value.id;
            }
            const assignEmployeePromise = api.assignEmployee(req);
            await Q.delay(400);
            const resp = await assignEmployeePromise;
            if (resp.errorMessage) {
                swal.fire({
                    title: resp.errorMessage || 'Error',
                    icon: 'error'
                });
                return;
            }
            swal.close();
            assignedEmployeeIds.value.push(employeeId);
            emit('assignEmployee', req);
            if (!wasEmployees) {
                const req: ChangeSlotEmployeeIcon = {
                    slotId: slot.value.id,
                    startDate: startDate.value,
                    remove: false
                };
                emit('changeEmployeeIcon', req);
            }
        }
        function selectTab(num: number) {
            selectedTab.value = num;
        }
        function formatMobile(mobile: string) {
            return mobile.startsWith('972') ? '0' + mobile.slice(3) : mobile;
        }
        return {
            back,
            open,
            showAll,
            assignEmployee,
            unassignEmployee,
            selectTab,
            isOpen,
            filter,
            assignedEmployees,
            availableEmployees,
            selectedTab,
            isMobile,
            formatMobile,
            startDate,
            slot,
            productName,
            durationName,
            isViewMode
        };
    }
});
