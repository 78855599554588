
import CalendarKendo from '@/components/Calendar/CalendarKendo.vue';

import swal from 'sweetalert2';
import { useRoute } from 'vue-router';
import { api } from '@/services/Api';
import { ref, defineComponent, onMounted } from 'vue';
import { OrderProduct } from '@/models/Order';
import useOrder from '@/modules/useOrder';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import mixin from '@/mixin';
import moment from 'moment';
import { SaveNewOrderProductDateRequest } from '@/models/Interfaces';
export default defineComponent({
    props: {
        id: {
            type: String,
            required: true
        }
    },
    components: { CalendarKendo },
    async setup(props) {
        const { t } = useI18n();
        const loaded = ref(false);
        const product = ref(new OrderProduct());
        const priceTypeId = ref('');
        const route = useRoute();
        const productId = ref('');
        const orderProductId = ref('');
        const slotId = ref('');
        const date = ref('');
        const customerId = ref('');

        function Cancel() {
            router.push({ name: 'order', params: { id: props.id } });
        }

        async function SaveNewOrderProductDate(ignoreQtyParam = false): Promise<boolean> {
            swal.showLoading();
            const time = moment(product.value.slot.startTime, 'HH:mm').format('HH:mm:ss');
            const request: SaveNewOrderProductDateRequest = {
                orderId: props.id,
                productId: orderProductId.value,
                customerId: customerId.value,
                slotId: product.value.slotId,
                date: product.value.date,
                ignoreQty: false,
                time: time
            };
            if (ignoreQtyParam) {
                request.ignoreQty = true;
            }
            const apiResult = await api.SaveNewOrderProductDate(request);
            if (apiResult.data?.errorCode == 6) {
                const swalResult = await swal.fire({
                    title: t('alert.areYouSure'),
                    text: t('alert.youHaveExceededTheQuantity'),
                    icon: 'warning',
                    customClass: {
                        confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-4',
                        cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                    },
                    buttonsStyling: false,
                    showCancelButton: true,
                    confirmButtonText: t('alert.yesContinue'),
                    cancelButtonText: t('button.cancel')
                });
                if (!swalResult.isConfirmed) {
                    return false;
                }
                request.ignoreQty = true;
                const apiResult2 = await api.SaveNewOrderProductDate(request);
                if (apiResult2.errorMessage) {
                    swal.fire({
                        title: apiResult2.errorMessage,
                        icon: 'error',
                        customClass: {
                            cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                        },
                        buttonsStyling: false,
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonText: t('button.cancel')
                    });
                    return false;
                }
                return true;
            }
            if (apiResult.errorMessage) {
                if (apiResult.errorCode == 547) {
                    // Some date
                    return true;
                }
                swal.fire({
                    title: apiResult.data?.error,
                    icon: 'error',
                    customClass: {
                        cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                    },
                    buttonsStyling: false,
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonText: t('button.cancel')
                });
                return false;
            }
            return true;
        }

        async function onselectslot({ slotId, slot, date, ignoreQty = false }: any) {
            product.value.date = date;
            product.value.slotId = slotId;
            product.value.slot = slot;
            const confirm = await SaveNewOrderProductDate(ignoreQty);
            if (confirm) {
                router.push({ name: 'order', params: { id: props.id } });
            }
        }
        function openOrder() {
            router.push({ name: 'order', params: { id: props.id } });
        }
        const onMountedCall = async () => {
            if (!route.params.productId || !route.params.orderProductId || !route.params.slotId || !route.params.date) {
                router.push({ name: 'order', params: { id: props.id } });
                return;
            }
            productId.value = route.params.productId.toString();
            orderProductId.value = route.params.orderProductId.toString();
            slotId.value = route.params.slotId.toString();
            date.value = route.params.date.toString();

            customerId.value = route.params.customerId.toString();
            const apiResalt = await api.getOrderProductDate(props.id, orderProductId.value, customerId.value);
            const result = apiResalt.data;
            if (result) {
                product.value.slotId = result.slot.id;
                product.value.slot = result.slot;
                product.value.productId = result.productId;
                product.value.date = result.date;
                product.value.calendarDate = mixin.methods.getDateToTitle(result.date);
                priceTypeId.value = result.priceTypeId;
                loaded.value = true;
            }
        };
        onMounted(onMountedCall);

        return { product, priceTypeId, Cancel, onselectslot, customerId, productId, slotId, date, loaded, openOrder };
    }
});
