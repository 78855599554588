import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, vModelRadio as _vModelRadio, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "tabs-switcher mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
      return (_openBlock(), _createBlock(_Fragment, {
        key: tab.id
      }, [
        _withDirectives(_createVNode("input", {
          type: "radio",
          name: _ctx.name,
          id: _ctx.guid + tab.id,
          value: tab.id,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.value = $event)),
          onChange: ($event: any) => {
                    _ctx.$emit('update:modelValue', tab.id);
                    _ctx.$emit('change', tab.id);
                }
        }, null, 40, ["name", "id", "value", "onChange"]), [
          [_vModelRadio, _ctx.value]
        ]),
        _createVNode("label", {
          class: "tab-toggle",
          for: _ctx.guid + tab.id
        }, _toDisplayString(tab.text), 9, ["for"])
      ], 64))
    }), 128))
  ]))
}