
import { defineComponent, PropType, ref, computed } from 'vue';
import OverlayModal from './OverlayModal.vue';
import WeekdaysSelect from './WeekdaysSelect.vue';
import swal from 'sweetalert2';
import { api } from '@/services/Api';
import Calendar from 'primevue/calendar';
import { useI18n } from 'vue-i18n';
import useSlots from '@/modules/useSlots';
import { ProductListItem } from '@/models/Product';
import { CalendarSlot, CalendarStopSale } from '@/models/Calendar';
import _ from 'lodash';
import moment from 'moment';
import Q from 'q';

export default defineComponent({
    components: { Calendar, OverlayModal, WeekdaysSelect },
    name: 'CalendarStopSaleModal',
    props: {
        products: {
            type: Array as PropType<ProductListItem[]>,
            default: () => []
        }
    },
    emits: ['stopCreated', 'stopRemoved', 'deleted', 'close'],
    data() {
        return {};
    },
    async setup(props, { emit }: any) {
        const step = ref(1);
        const stopsaleType = ref<number | null>(null);
        const { t } = useI18n();
        const { createStopSale, weekDayNames, revertedDays } = useSlots();
        const product = ref(new ProductListItem());

        const slot = ref<CalendarSlot>(new CalendarSlot());
        const stopSale = ref<CalendarStopSale>(new CalendarStopSale());
        const isOpen = ref(false);
        const today = ref(new Date());
        const { createStopSaleFull } = useSlots();

        const isDate = (val: string | Date) => ((val as any)?.getDate ? true : false);
        function getMinutes(time: string) {
            const [hours, mins] = time.split(':');
            return parseInt(hours) * 60 + parseInt(mins);
        }
        const disabledDays = computed(() => {
            return _.difference(Object.values(weekDayNames), slot.value.daysOfWeek);
        });
        const back = () => {
            step.value = 1;
            stopsaleType.value = null;
            emit('close');
            isOpen.value = false;
        };
        async function save() {
            if (stopsaleType.value == 2) {
                stopSale.value.endDate = stopSale.value.startDate;
            }
            if (!stopSale.value.startDate || !stopSale.value.endDate) {
                swal.fire({
                    icon: 'info',
                    title: t('calendar.v3.stopsale.warning.empty-date')
                });
                return;
            }

            const model = CalendarStopSale.clone(stopSale.value);
            model.startDate = moment(model.startDate)
                .format()
                .substr(0, 10);
            model.endDate = moment(model.endDate)
                .format()
                .substr(0, 10);
            const allSlots = stopsaleType.value == 2 || stopsaleType.value == 3;
            if (allSlots) {
                model.slotId = '';
                model.productId = product.value.id;
            }
            swal.showLoading();
            const resp = await createStopSale(model, allSlots);
            if (!resp) return;
            if (!resp.deletedSlotIds.length && !resp.stopSale.length) {
                swal.fire({
                    icon: 'info',
                    title: 'Slot does not exist'
                });
                back();
                return;
            }
            resp.deletedSlotIds.forEach(deletedSlotId => {
                emit('deleted', deletedSlotId);
            });
            resp.stopSale.forEach(stopSale => {
                emit('stopCreated', CalendarStopSale.clone(stopSale));
            });
            swal.close();
            back();
        }

        async function revert(stop: CalendarStopSale) {
            const swalResult = await swal.fire({
                icon: 'info',
                title: t('calendar.v3.stopsale.warning.confirm_revert'),
                customClass: {
                    confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                    denyButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showDenyButton: true,
                showCloseButton: true,
                confirmButtonText: t('alert.yesContinue'),
                denyButtonText: t('button.cancel')
            });
            if (!swalResult.isConfirmed) return null;
            await Q.delay(150);
            swal.showLoading();
            const resp = await api.revertCalendarStopSale(stop.id);

            if (resp.errorMessage || !resp.data) {
                swal.fire({
                    title: resp.errorMessage || 'Error',
                    icon: 'error'
                });
                return;
            }
            await Q.delay(350);
            swal.fire({ icon: 'success' });
            slot.value.repeatStops = slot.value.repeatStops.filter(x => x.id != stop.id);
            emit('stopRemoved', stop);
        }
        function open(newSlot: CalendarSlot, p: ProductListItem, date: Date = new Date()) {
            slot.value = CalendarSlot.clone(newSlot);
            product.value = p;
            stopSale.value = new CalendarStopSale();
            stopSale.value.slotId = newSlot.id;
            today.value = new Date();
            today.value.setHours(0, 0, 0, 0);
            /*
            if (date > today.value) {
                stopSale.value.startDate = date;
                stopSale.value.endDate = date;
            }
            if (stopSale.value.startDate) {
                stopSale.value.daysOfWeek.push(getDayFromDate(stopSale.value.startDate as Date));
            }
            */
            isOpen.value = true;
        }
        function minDateAsToday(date: Date) {
            const now = new Date();
            if (!date) return now;
            if (date < now) return now;
            return date;
        }
        function stopCurrentSlot() {
            if (slot.value.repeatMode == 1) {
                swal.fire({
                    icon: 'info',
                    title: t('calendar.v3.warning.confirm_remove'),
                    customClass: {
                        confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                        denyButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                    },
                    buttonsStyling: false,
                    showDenyButton: true,
                    showCloseButton: true,
                    confirmButtonText: t('alert.yesContinue'),
                    denyButtonText: t('button.cancel')
                }).then(async swalResult => {
                    if (!swalResult.isConfirmed) return null;
                    const result = await createStopSaleFull(slot.value.id, false, true);
                    if (result) {
                        emit('deleted', slot.value.id);
                        back();
                    }
                });
                return;
            }
            step.value = 2;
            stopsaleType.value = 1;
        }
        return {
            back,
            stopSale,
            product,
            isDate,
            weekDayNames,
            isOpen,
            open,
            slot,
            getMinutes,
            event,
            disabledDays,
            minDateAsToday,
            revert,
            today,
            save,
            step,
            stopsaleType,
            stopCurrentSlot
        };
    }
});
