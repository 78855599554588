
import { defineComponent, ref } from 'vue';
import CheckBoxInput from '../CheckBoxInput.vue';

export default defineComponent({
  name: "NotificationSlotModal",
  components: {
    CheckBoxInput
  },
  emits: ['updateVisibility', 'confirmed'],
  setup(props, { emit }) {
    const isSms = ref(false);
    const isEmail = ref(false);

    function close() {
      emit('updateVisibility', false);
      isEmail.value = false;
      isSms.value = false;
    }

    function confirm() {
      emit('confirmed', {
        sendSms: isSms.value,
        sendEmail: isEmail.value
      });

      close();
    }

    const canConfirm = () => isEmail.value || isSms.value;

    function confirmButtonClass() {
      return canConfirm() ? 'save-button-wrapper' : 'grey-button-wrapper';
    }

    return {
      isSms,
      isEmail,
      close,
      confirm,
      canConfirm,
      confirmButtonClass
    };
  }
});
