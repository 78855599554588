
import { defineComponent, PropType, ref } from 'vue';
import OverlayModal from './OverlayModal.vue';
import swal from 'sweetalert2';
import { api } from '@/services/Api';
import { useI18n } from 'vue-i18n';
import useSlots from '@/modules/useSlots';
import { ProductListItem } from '@/models/Product';
import { CalendarSlot, getTime } from '@/models/Calendar';
import _ from 'lodash';
import moment from 'moment';
import Q from 'q';

export default defineComponent({
    components: { OverlayModal },
    emits: ['revert', 'close'],
    data() {
        return {};
    },
    async setup(props, { emit }: any) {
        const { t } = useI18n();
        const { revertSlot } = useSlots();
        const product = ref(new ProductListItem());

        const slot = ref<CalendarSlot>(new CalendarSlot());
        const slots = ref<CalendarSlot[]>([]);
        const isOpen = ref(false);
        const today = ref(new Date());

        const back = () => {
            emit('close');
            isOpen.value = false;
        };
        async function revert(slot: CalendarSlot) {
            const swalResult = await swal.fire({
                icon: 'info',
                title: t('calendar.v3.stopsale.warning.confirm_revert'),
                customClass: {
                    confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                    denyButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showDenyButton: true,
                showCloseButton: true,
                confirmButtonText: t('alert.yesContinue'),
                denyButtonText: t('button.cancel')
            });
            if (!swalResult.isConfirmed) return null;
            await Q.delay(150);
            swal.showLoading();
            const resp = await revertSlot(slot.id);
            if (!resp) return;
            await Q.delay(350);
            swal.fire({ icon: 'success' });
            slots.value = slots.value.filter(x => x.id != slot.id);
            emit('revert', slot);
        }
        function open(s: CalendarSlot, p: ProductListItem, slotsHistory: CalendarSlot[] = []) {
            slot.value = CalendarSlot.clone(s);
            product.value = p;
            slots.value = _.orderBy(slotsHistory, x => x.startDate, 'desc');

            today.value = new Date();
            today.value.setHours(0, 0, 0, 0);
            isOpen.value = true;
        }
        function minDateAsToday(date: Date) {
            const now = new Date();
            if (!date) return now;
            if (date < now) return now;
            return date;
        }
        return {
            back,
            product,
            isOpen,
            open,
            slots,
            slot,
            event,
            minDateAsToday,
            getTime,
            revert,
            today
        };
    }
});
