
import { OrderDetails } from '@/models/Order';
import { defineComponent, PropType, computed } from 'vue';
import Multiselect from '@vueform/multiselect';
import { IdText, PriceType, BussinesClientByNewOrderObject } from '@/models/Interfaces';

export default defineComponent({
    name: 'OrderDataView',
    props: {
        modelValue: {
            type: Object as PropType<OrderDetails>, // Type Annotation
            default: () => ({})
        },
        incorrectEmail: {
            type: Boolean,
            required: true
        },
        incorrectMobile: {
            type: Boolean,
            required: true
        },
        isGroupPrice: {
            type: Boolean,
            required: true
        },
        orderProductExist: {
            type: Boolean,
            required: true
        },
        channels: {
            type: Array as PropType<IdText[]>,
            required: true
        },
        priceTypes: {
            type: Array as PropType<PriceType[]>,
            required: true
        },
        clients: {
            type: Array as PropType<BussinesClientByNewOrderObject[]>,
            required: true
        }
    },
    components: { Multiselect },
    async setup(props, { emit }) {
        const theModel = computed({
            get: () => props.modelValue,
            set: value => emit('update:modelValue', value)
        });

        function emailChanged() {
            emit('emailChanged');
        }
        function mobileNumberChanged() {
            theModel.value.leadCustomer.mobile = theModel.value.leadCustomer.mobile.replace(/[^\s+\-0-9]/g, '');
            emit('mobileNumberChanged');
        }
        function getPriceCustomersOnPriceTypeChange(e: any) {
            emit('getPriceCustomersOnPriceTypeChange', e);
        }
        function onBussinessClientChange(e: number | null) {
            emit('onBussinessClientChange', e);
        }

        return {
            theModel,
            emailChanged,
            mobileNumberChanged,
            getPriceCustomersOnPriceTypeChange,
            onBussinessClientChange
        };
    }
});
