
import { defineComponent, PropType, ref, computed, watch } from 'vue';
import OverlayModal from './OverlayModal.vue';
import Multiselect from '@vueform/multiselect';
import swal, {SweetAlertResult} from 'sweetalert2';
import { api } from '@/services/Api';
import TimeInput from '@/components/TimeInput.vue';
import RadioInput from '@/components/RadioInput.vue';
import TabToggle from '@/components/TabToggle.vue';
import Calendar from 'primevue/calendar';
import { useI18n } from 'vue-i18n';
import useSlots from '@/modules/useSlots';
import { ProductListItem } from '@/models/Product';
import { CalendarSlot, CalendarEvent } from '@/models/Calendar';
import { DayOfWeekKendo, OrderBlockOption, OrderBlockTypes } from '@/models/Enums';
import moment from 'moment';
import { Dictionary } from 'lodash';
import InputNumber from 'primevue/inputnumber';
import useProduct from '@/modules/useProduct';
import { SendEmailSmsAction } from '@/models/MessageTemplate';
import NotificationSlotModal from './NotificationSlotModal.vue';
import { generalStore } from '@/store';

export const CalendarModeList = ['slot', 'event'] as const;
export type CalendarMode = typeof CalendarModeList[number];

export default defineComponent({
    components: { Multiselect, TimeInput, Calendar, RadioInput, TabToggle, OverlayModal, InputNumber, NotificationSlotModal },
    name: 'CalendarSlotModal',
    props: {
        products: {
            type: Array as PropType<ProductListItem[]>,
            default: () => []
        },
        showProduct: {
            type: Boolean,
            default: true
        },
        canMoveOrders: {
            type: Boolean,
            default: false
        },
        isSoldOut: {
            type: Boolean,
            default: false
        }
    },
    emits: ['save', 'saveEvent', 'close', 'sendSmsEmail'],
    data() {
        return {};
    },
    async setup(props, { emit }: any) {
        const { t } = useI18n();
        const weekDayNames = DayOfWeekKendo;
        const slotLimit = ref<string | null>(null);
        const endLimit = ref('date');
        const showExtraInfoAbout = ref(false);
        const mode = ref<CalendarMode>('slot');
        const { saveSlot } = useSlots();
        const { orderBlockTypesOpt, orderBlockOptionsOpt } = useProduct();
        const selectedOrderBlockType = ref<OrderBlockTypes>(OrderBlockTypes.minutes);
        const selectedOrderBlockOption = ref<OrderBlockOption>(OrderBlockOption.after);
        const orderBlockTypes = orderBlockTypesOpt();
        const orderBlockOptions = orderBlockOptionsOpt();

        const slot = ref<CalendarSlot>(new CalendarSlot());
        const event = ref<CalendarEvent>(new CalendarEvent());
        const isOpen = ref(false);
        const parentSlotDate = ref('');
        const isSlot = computed(() => mode.value == 'slot');
        const canChangeMode = computed(() => (isSlot.value ? !slot.value.id && !slot.value.originalSlotId : !event.value.id));
        const changeDateMode = ref(false);
        const editExistOrder = ref(false);
        const showNotificationModal = ref(false);
        const currentLang = generalStore.getters.currentLanguage;
        const canCreateChangeSlotInPast = generalStore.getters.canCreateChangeSlotInPast;
        const sendEmailRequest: SendEmailSmsAction = {
            template: {
                id: '',
                emailSenderName: 'notification@bookitpms.com',
                emailSubject: t('calendar.slot.orderNotificationEmailSubject'),
                isRtl: currentLang == 'he-IL',
                name: t('calendar.slot.orderNotificationEmailSubject'),
                sendEmail: false,
                sendSms: false,
                text: t('calendar.slot.orderNotificationText'),
                smsSenderName: 'bookit',
                attachments: []
            },
            slotId: '',
            productId: '',
            date: ''
        };

        let isSlotExistDateTimeChanged = false;
        let dateAndTimeChangeCounter = 0;
        let warningTimeMessage = '';

        const dayOfWeek = computed(() => {
            const day = moment(slot.value.startDate, moment.ISO_8601).day();
            return Object.values(DayOfWeekKendo)[day] ?? '';
        });
        function reCalculateOrderBlockMin() {
            if (slot.value.orderBlockMinutes == null) {
                slot.value.blockCreatingNewOrdersBefore = false;
                selectedOrderBlockOption.value = OrderBlockOption.allow;
                return;
            }

            if (slot.value.orderBlockMinutes == 0) {
                selectedOrderBlockOption.value = OrderBlockOption.after;
                slot.value.blockCreatingNewOrdersBefore = false;
                return;
            }

            slot.value.blockCreatingNewOrdersBefore = true;
            selectedOrderBlockOption.value = OrderBlockOption.before;
            if (slot.value.orderBlockMinutes % (24 * 60) == 0) {
                selectedOrderBlockType.value = OrderBlockTypes.days;
                slot.value.orderBlockMinutes = slot.value.orderBlockMinutes / 60 / 24;
            } else if (slot.value.orderBlockMinutes % 60 == 0) {
                selectedOrderBlockType.value = OrderBlockTypes.hours;
                slot.value.orderBlockMinutes = slot.value.orderBlockMinutes / 60;
            } else {
                selectedOrderBlockType.value = OrderBlockTypes.minutes;
            }
        }
        watch(
            () => slot.value.productId,
            x => {
                if (props.products && props.products.length > 0) {
                    slot.value.extraInfo = props.products.find(x => x.id === slot.value.productId)?.extraInfo as string;
                }
            }
        );
        const isDate = (val: string | Date) => ((val as any)?.getDate ? true : false);
        function getMinutes(time: string) {
            const [hours, mins] = time.split(':');
            return parseInt(hours) * 60 + parseInt(mins);
        }

        const isProductEditable = ref(true);
        function validate() {
            const hasRepeat = slot.value.repeatMode > 1;
            let limit = false;
            if (slotLimit.value && slotLimit.value == 'count') {
                const l = slot.value.limit ?? -1;
                if (String(l) !== '0') {
                    limit = !((slot.value.limit || 0) * 1) || l < 0 || l > 1_000_000 || l % 1 !== 0;
                }
            }
            return {
                productId: !slot.value.productId,
                startDate: !isDate(slot.value.startDate),
                repeatEndDate:
                    hasRepeat &&
                    endLimit.value == 'date' &&
                    (!isDate(slot.value.repeatEndDate || '') ||
                        (isDate(slot.value.startDate) && (slot.value.startDate as Date) > (slot.value.repeatEndDate as Date))),
                repeatCount: hasRepeat && endLimit.value == 'count' && !((slot.value.repeatCount || 0) * 1),
                limit: limit,
                startTime: !slot.value.startTime,
                endTime: slot.value.startTime && !slot.value.endTime,
                blockCreatingOrdersBefore: slot.value.blockCreatingNewOrdersBefore && !slot.value.orderBlockMinutes
            };
        }
        const invalid = ref(validate());

        function validateEvent() {
            return {
                name: !event.value.name,
                startDate: !isDate(event.value.startDate),
                endDate: !isDate(event.value.endDate) || event.value.startDate > event.value.endDate,
                startTime: !event.value.startTime,
                endTime:
                    !event.value.endTime ||
                    (event.value.startDate == event.value.endDate && getMinutes(event.value.startTime) > getMinutes(event.value.endTime))
            };
        }
        const invalidEvent = ref(validateEvent());

        function updateEndTime(field: string, val?: string) {
            if (val) {
                if (field == 'start') {
                    slot.value.startTime = val;
                } else if (field == 'end') {
                    slot.value.endTime = val;
                } else if (field == 'duration') {
                    slot.value.duration = val;
                }
            }
            if (!slot.value.startTime) return;
            const startMins = getMinutes(slot.value.startTime);
            const start = moment.utc(0).add(startMins, 'minutes');
            if (field == 'start' || field == 'end') {
                if (!slot.value.endTime) return;

                let mins = getMinutes(slot.value.endTime);
                if (mins < startMins) mins += 24 * 60;
                const end = moment.utc(0).add(mins, 'minutes');

                slot.value.duration = moment.utc(end.diff(start)).format('HH:mm');
                if (slot.value.duration === '00:00') slot.value.duration = '24:00';
            } else if (field == 'duration') {
                if (!slot.value.duration) return;
                const end = start.clone().add(getMinutes(slot.value.duration), 'minutes');
                slot.value.endTime = end.format('HH:mm');
            }
        }

        const back = () => {
            emit('close');
            selectedOrderBlockType.value = OrderBlockTypes.minutes;
            isOpen.value = false;
        };
        const validateSaveSlot = async () => {
            invalid.value = validate();
            // has validation errors;
            if (Object.values(invalid.value).filter(x => x).length > 0) {
                return;
            }
            const model = CalendarSlot.clone(slot.value);
            if (selectedOrderBlockOption.value === OrderBlockOption.after) {
                model.orderBlockMinutes = 0;
            }
            if (selectedOrderBlockOption.value === OrderBlockOption.allow) {
                model.orderBlockMinutes = null;
            }

            if (selectedOrderBlockOption.value === OrderBlockOption.before) {
                if (!model.orderBlockMinutes) {
                    model.orderBlockMinutes = null;
                    return;
                }
                if (model.orderBlockMinutes != null && selectedOrderBlockType.value == OrderBlockTypes.days) {
                    model.orderBlockMinutes = model.orderBlockMinutes * 60 * 24;
                } else if (model.orderBlockMinutes != null && selectedOrderBlockType.value == OrderBlockTypes.hours) {
                    model.orderBlockMinutes = model.orderBlockMinutes * 60;
                }
            }
            model.startDate = moment(model.startDate)
                .format()
                .substr(0, 10);
            // has Repeat
            if (model.repeatMode > 1) {
                if (endLimit.value == 'count') {
                    model.repeatEndDate = null;
                    model.repeatCount = parseInt((model.repeatCount ?? 0).toString());
                } else {
                    model.repeatCount = null;
                    model.repeatEndDate = model.repeatEndDate
                        ? moment(model.repeatEndDate)
                              .format()
                              .substr(0, 10)
                        : null;
                }
            } else {
                model.repeatCount = null;
                model.repeatEndDate = null;
            }
            model.startTime += ':00';
            model.endTime += ':00';
            model.duration += ':00';
            if (!slotLimit.value) {
                model.limit = null;
            } else if (slotLimit.value == 'order-qty') {
                model.limit = model.id ? model.limit : null;
                model.isVirtual = true;
            } else {
                model.limit = (model.limit || 0) * 1;
            }
            if (model.limit === 0) {
                const slotZeroQtyAccepted = await swal.fire({
                    text: t('product.priceList.zeroLimit'),
                    icon: 'question',
                    customClass: {
                        confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                        cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                    },
                    buttonsStyling: false,
                    showCancelButton: true,
                    confirmButtonText: t('button.yes'),
                    cancelButtonText: t('button.no')
                });
                if (!slotZeroQtyAccepted.isConfirmed) {
                    return;
                }
            }

            let moveOrders = false;
            let ignoreOrderWarning = false;
            let moveOrderSwalRes: SweetAlertResult<any> | undefined = undefined;

            if(props.canMoveOrders && isSlotExistDateTimeChanged && (slot.value.id || slot.value.originalSlotId) && slot.value.repeatMode === 1){
                moveOrderSwalRes = await swal.fire({
                    icon: 'info',
                    title: t('calendar.slot.moveOrderSwalTitle'),
                    customClass: {
                        confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                        denyButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                    },
                    buttonsStyling: false,
                    showDenyButton: true,
                    showCloseButton: true,
                    confirmButtonText: t('alert.yesContinue'),
                    denyButtonText: t('moveOrder.swal-no-move-orders')
                });

                if (moveOrderSwalRes.isConfirmed){
                    if(warningTimeMessage){
                        const warningTimeResult = await swal.fire({
                            icon: 'warning',
                            title: warningTimeMessage,
                            customClass: {
                                confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                                denyButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                            },
                            buttonsStyling: false,
                            showDenyButton: true,
                            showCloseButton: true,
                            confirmButtonText: t('alert.yes'),
                            denyButtonText: t('alert.no')
                        });

                        if(!warningTimeResult.isConfirmed) return;
                    }                        

                    moveOrders = true;                   
                    ignoreOrderWarning = true;                   
                }else if(props.isSoldOut){
                    model.id = "";
                }            
            }

            swal.showLoading();
            const savedSlot = await saveSlot(model, parentSlotDate.value, ignoreOrderWarning, undefined, moveOrders);
            if (!savedSlot) return;
            swal.close();

            if(moveOrderSwalRes && moveOrderSwalRes.isConfirmed){
                sendEmailRequest.date = moment(`${model.startDate} ${model.startTime}`).format('DD/MM/YYYY HH:mm');
                sendEmailRequest.slotId = savedSlot.id;
                sendEmailRequest.productId = savedSlot.productId;
                showNotificationModal.value = true;
            }            

            model.id = savedSlot.id;
            emit('save', model);
            isOpen.value = false;
        };
        const saveEvent = async () => {
            invalidEvent.value = validateEvent();
            // has validation errors;
            if (Object.values(invalidEvent.value).filter(x => x).length > 0) {
                return;
            }
            const model = CalendarEvent.clone(event.value);
            model.startDate = moment(model.startDate)
                .format()
                .substr(0, 10);
            model.endDate = moment(model.endDate)
                .format()
                .substr(0, 10);
            model.startTime += ':00';
            model.endTime += ':00';
            swal.showLoading();
            const resp = await api.saveCalendarEvent(model);
            if (resp.errorMessage || !resp.data) {
                swal.fire({
                    title: resp.errorMessage || 'Error',
                    icon: 'error'
                });
                return;
            }
            swal.close();
            model.id = resp.data.event.id;
            emit('saveEvent', model);
            isOpen.value = false;
        };
        async function save() {
            if (mode.value == 'slot') {
                await validateSaveSlot();
            } else {
                saveEvent();
            }
        }
        function open(newSlot: CalendarSlot, productIsEditable = true, changeDate = false, isEditExistOrder = false) {
            mode.value = 'slot';
            Object.keys(invalid.value).forEach(k => ((invalid.value as Dictionary<boolean>)[k] = false));
            Object.keys(invalidEvent.value).forEach(k => ((invalidEvent.value as Dictionary<boolean>)[k] = false));
            slot.value = CalendarSlot.clone(newSlot);
            if (!slot.value.duration) {
                updateEndTime('end');
            }
            reCalculateOrderBlockMin();
            parentSlotDate.value = '';
            if (newSlot.originalSlotId) {
                parentSlotDate.value = moment(newSlot.startDate).format('YYYY-MM-DD');
            }
            slotLimit.value = slot.value.connection != null ? 'order-qty' : slot.value.limit !== null ? 'count' : null;
            endLimit.value = slot.value.repeatCount || 0 > 0 ? 'count' : 'date';
            event.value = new CalendarEvent();
            event.value.startDate = slot.value.startDate;
            event.value.endDate = slot.value.startDate;
            isProductEditable.value = productIsEditable;
            changeDateMode.value = changeDate;
            editExistOrder.value = isEditExistOrder;
            isOpen.value = true;
            dateAndTimeChangeCounter = 0;
            isSlotExistDateTimeChanged = false;
        }
        function openEvent(newEvent: CalendarEvent) {
            mode.value = 'event';
            Object.keys(invalid.value).forEach(k => ((invalid.value as Dictionary<boolean>)[k] = false));
            Object.keys(invalidEvent.value).forEach(k => ((invalidEvent.value as Dictionary<boolean>)[k] = false));
            event.value = CalendarEvent.clone(newEvent);
            if (!slot.value.duration) {
                updateEndTime('end');
            }
            slot.value = new CalendarSlot();
            isOpen.value = true;
        }
        function modeChanged(mode: CalendarMode) {
            if (mode == 'slot') {
                slot.value.startDate = event.value.startDate;
                slot.value.startTime = event.value.startTime;
                slot.value.endTime = event.value.endTime;
                updateEndTime('end');
            } else if (mode == 'event') {
                event.value.startDate = slot.value.startDate;
                event.value.startTime = slot.value.startTime;
                event.value.endTime = slot.value.endTime;
                if (event.value.endDate < event.value.startDate) {
                    event.value.endDate = event.value.startDate;
                }
            }
        }

        function setRepeat(mode: number) {
            if (mode == 3) {
                slot.value.daysOfWeek = [];
            } else if (mode == 2) {
                slot.value.daysOfWeek = Object.values(weekDayNames);
            } else {
                mode = 1;
            }
            slot.value.repeatMode = mode;
        }
        function filterSlotLimitInput(event: any) {
            if (event.key.trim() === '' || isNaN(event.key)) {
                event.preventDefault();
            }
        }
        function checkEventDescription() {
            if (event.value.description.length > 300) {
                event.value.description = event.value.description.substring(0, 300);
            }
        }

        function checkExtraInfo() {
            if (slot.value.extraInfo.length > 255) {
                slot.value.extraInfo = slot.value.extraInfo.substring(0, 255);
            }
        }
        function watchAtOption(event: OrderBlockOption) {
            if (event === OrderBlockOption.before) {
                slot.value.blockCreatingNewOrdersBefore = true;
            } else {
                slot.value.blockCreatingNewOrdersBefore = false;
            }
            slot.value.orderBlockMinutes = null;
        }
        function setProductOrderBlockMinutes(event: string) {
            slot.value.orderBlockMinutes = props.products.find(x => x.id === event)?.orderBlockMinutes as number;
            reCalculateOrderBlockMin();
        }

        function getFormattedDate(date: string, time: string) {
            let formattedDate = '';
            
            if (!date) return formattedDate;            

            if (time) {
                const [hour, minute] = time.split(':').map(Number);
                formattedDate = moment(date).set({ hour, minute }).toISOString();
            }

            return formattedDate;
        }

        watch([() => slot.value.startDate, () => slot.value.startTime], 
        ([newStartDate, newStartTime], [oldStartDate, oldStartTime]) => {
            dateAndTimeChangeCounter ++;
            if(dateAndTimeChangeCounter < 2) return;

            const hasChanged = newStartDate !== oldStartDate || newStartTime !== oldStartTime;

            if ((slot.value.id || slot.value.originalSlotId) && hasChanged) {
                const currentDate = moment().toISOString();
                const oldDate = getFormattedDate(oldStartDate.toString(), oldStartTime.toString());
                const newDate =  getFormattedDate(newStartDate.toString(), newStartTime.toString());                

                if(currentDate >= newDate || currentDate >= oldDate){
                    if(newDate > oldDate)
                        warningTimeMessage = t('moveOrder.warning-future-to-past');
                    else if(oldDate > newDate)
                        warningTimeMessage = t('moveOrder.warning-past-to-future');
                    else
                        warningTimeMessage = '';
                }else {
                    warningTimeMessage = '';
                }

                isSlotExistDateTimeChanged = true;
            } else {
                isSlotExistDateTimeChanged = false;
                warningTimeMessage = '';
            }
        });        

        function updateNotificationModalVisibility(newValue: boolean) {
            showNotificationModal.value = newValue;
        }

        function notificationSlotModalConfirmed(payload: { sendSms: boolean; sendEmail: boolean }) {
            if(!sendEmailRequest.slotId) return;

            sendEmailRequest.template.sendSms = payload.sendSms;
            sendEmailRequest.template.sendEmail = payload.sendEmail;
            emit('sendSmsEmail', sendEmailRequest);
        }

        return {
            mode,
            modeChanged,
            back,
            slot,
            isDate,
            weekDayNames,
            isOpen,
            open,
            dayOfWeek,
            slotLimit,
            endLimit,
            getMinutes,
            invalid,
            setRepeat,
            updateEndTime,
            isSlot,
            openEvent,
            invalidEvent,
            event,
            canChangeMode,
            save,
            filterSlotLimitInput,
            isProductEditable,
            selectedOrderBlockType,
            orderBlockTypes,
            checkEventDescription,
            checkExtraInfo,
            showExtraInfoAbout,
            watchAtOption,
            selectedOrderBlockOption,
            orderBlockOptions,
            setProductOrderBlockMinutes,
            changeDateMode,
            editExistOrder,
            showNotificationModal,
            notificationSlotModalConfirmed,
            updateNotificationModalVisibility,
            canCreateChangeSlotInPast
        };
    }
});
