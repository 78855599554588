
import { computed, defineComponent, ref } from 'vue';
import OverlayModal from '@/components/Calendar/OverlayModal.vue';
import swal from 'sweetalert2';
import q from 'q';
import { useI18n } from 'vue-i18n';
import { api } from '@/services/Api';
import { MessageTemplate, SendEmailSmsAction } from '@/models/MessageTemplate';
import CheckBoxInput from '@/components/CheckBoxInput.vue';
import Multiselect from '@vueform/multiselect';
import moment from 'moment';
import { generalStore } from '@/store';
import { DefaultProviderSettings, IdName } from '@/models/Interfaces';
import _ from 'lodash';

export default defineComponent({
    components: {
        OverlayModal,
        CheckBoxInput,
        Multiselect
    },
    props: {
        fromManagement: {
            type: Boolean,
            default: false
        }
    },
    name: 'SaveSendNotificationSmsEmailTemplate',
    async setup(props, { emit }) {
        const isOpen = ref(false);
        const { t } = useI18n();
        const template = ref<MessageTemplate | null>();
        const selectedTemplate = ref<IdName>();
        const isNew = ref(false);
        const textArea = ref();
        const useDefinedTemplateClicked = ref(false);
        const saveTemplateClicked = ref(false);
        const templates = ref<IdName[]>([]);
        const templateNameBeforeSave = ref('');
        const templateBeforeEdit = ref<MessageTemplate | null>(null);
        const fileInput = ref();
        const files = ref<File[]>([]);
        const templateOptions = ref();
        const slotId = ref('');
        const productId = ref('');
        const defaultProviderSettings = ref<DefaultProviderSettings | null>(generalStore.state.defaultProviderSettings);
        const date = ref<string>('');
        const loading = ref(false);
        const templateForm = ref();

        const isValidTemplate = computed(() => {
            if (!template.value) {
                return false;
            }
            if (!template.value.text) {
                return false;
            }
            if (!template.value.sendEmail && !template.value.sendSms) {
                return false;
            }
            if (template.value.sendEmail) {
                if (!template.value.emailSenderName) {
                    return false;
                }
                if (!template.value.emailSubject) {
                    return false;
                }
            }
            if (template.value.sendSms) {
                if (!template.value.smsSenderName) {
                    return false;
                }
            }
            return true;
        });

        async function open(
            req: MessageTemplate | null = null,
            slotIdProp = '',
            dateProp: Date | null = null,
            productIdProp: string | null = null
        ) {
            files.value = [];
            templateForm.value.classList.remove('was-validated');
            if (req) {
                template.value = req;
                isNew.value = false;
            } else {
                template.value = {
                    id: '',
                    emailSenderName: defaultProviderSettings.value?.defaultProviderSettingsEmailSenderName ?? '',
                    emailSubject: '',
                    isRtl: false,
                    name: '',
                    sendEmail: false,
                    sendSms: false,
                    text: '',
                    smsSenderName: defaultProviderSettings.value?.defaultProviderSettingsSmsSenderName ?? '',
                    attachments: []
                };
                selectedTemplate.value = {
                    id: '',
                    name: ''
                };
                isNew.value = true;
            }
            templateBeforeEdit.value = JSON.parse(JSON.stringify(template.value));
            useDefinedTemplateClicked.value = false;
            if (slotIdProp) {
                slotId.value = slotIdProp;
            }
            if (dateProp != null) {
                date.value = moment(dateProp).format('DD/MM/YYYY HH:mm');
            }
            if (productIdProp != null) {
                productId.value = productIdProp;
            }

            isOpen.value = true;
        }
        function close() {
            isOpen.value = false;
        }
        async function CreateEditTemplate() {
            swal.showLoading();

            if (files.value.length > 0) {
                const fileTypes: string[] = ['doc', 'docx', 'xls', 'xlsx', 'txt', 'pdf', 'png', 'ppt', 'jpg', 'gif', 'mp3', 'mp4'];
                let errorFileTypeSize = false;
                fileInput.value.files.forEach((file: any) => {
                    const fileExt = file.name.split('.').pop();
                    if (!fileTypes.includes(fileExt)) {
                        swal.fire({
                            icon: 'error',
                            text: 'Error file type'
                        });
                        errorFileTypeSize = true;
                        return;
                    }

                    if (file.size > 5242880) {
                        swal.fire({
                            icon: 'error',
                            text: 'File size should be less or equal to 5 MB'
                        });
                        errorFileTypeSize = true;
                        return;
                    }
                });

                if (errorFileTypeSize) {
                    errorFileTypeSize = false;
                    return;
                }
            }

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };

            if (template.value) {
                const responsePromiseTemplate = api.SaveMessageTemplate(template.value);

                await q.delay(400);
                const responseTemplate = await responsePromiseTemplate;
                if (responseTemplate.errorMessage) {
                    swal.fire({
                        icon: 'error',
                        text: responseTemplate.errorMessage || 'error'
                    });
                    return;
                }

                const templateId = responseTemplate.data!.id;
                const attachments = template.value?.attachments;
                template.value = responseTemplate.data;
                template.value!.attachments = attachments;
                for (const file of fileInput.value.files) {
                    const fd = new FormData();

                    fd.append('file', file, file.name);
                    const responsePromiseTemplateAttachment = api.saveAttachmentFile(templateId, fd, config);
                    const responseTemplateAttachment = await responsePromiseTemplateAttachment;
                    if (responseTemplateAttachment.errorMessage) {
                        swal.fire({
                            icon: 'error',
                            text: responseTemplateAttachment.errorMessage || 'error'
                        });
                        return;
                    }
                }

                if (isNew.value) {
                    emit('pushTemplate', template.value);
                } else {
                    emit('changeTemplate', template.value);
                }
            }

            swal.close();
            close();
        }

        async function useDefinedTemplate() {
            loading.value = true;
            const apiPromise = api.getMessageTemplatesLightList();
            await q.delay(400);
            const resp = await apiPromise;
            if (resp.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: resp.errorMessage
                });
                return;
            }
            if (resp.data) {
                templates.value = resp.data.rows;
            }

            useDefinedTemplateClicked.value = !useDefinedTemplateClicked.value;
            await q.delay(300);
            templateOptions.value.open();
            loading.value = false;
        }

        function addKeyToText(key: string) {
            const startPos = textArea.value.selectionStart;
            const endPos = textArea.value.selectionEnd;
            const tmpStr = textArea.value.value;
            if (template.value) template.value.text = tmpStr.substring(0, startPos) + key + tmpStr.substring(endPos, tmpStr.length);
        }

        async function selectTemplate() {
            templateOptions.value.close();
            if (selectedTemplate.value && selectedTemplate.value!.id) {
                swal.showLoading();
                const apiPromise = api.getMessageTemplateDetails(selectedTemplate.value.id);
                await q.delay(400);
                const resp = await apiPromise;
                if (resp.errorMessage) {
                    swal.fire({
                        icon: 'error',
                        text: resp.errorMessage
                    });
                    return;
                }
                if (resp.data) {
                    template.value = resp.data;
                    templateBeforeEdit.value = JSON.parse(JSON.stringify(template.value));
                }
                swal.close();
            } else {
                template.value = {
                    id: '',
                    emailSenderName: '',
                    emailSubject: '',
                    isRtl: false,
                    name: '',
                    sendEmail: false,
                    sendSms: false,
                    text: '',
                    smsSenderName: '',
                    attachments: []
                };
            }

            templateNameBeforeSave.value = template.value!.name;
        }

        async function saveTemplate() {
            swal.showLoading();
            template.value!.id = '';
            swal.close();

            const responsePromise = api.createOrEditMessageTemplate(template.value!);
            await q.delay(400);
            const response = await responsePromise;
            if (response.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: response.errorMessage || 'error'
                });
                return;
            }

            template.value = response.data;
            templateNameBeforeSave.value = template.value?.name ?? '';
            saveTemplateClicked.value = false;
            swal.close();
        }

        function cancelEdit() {
            if (template.value) {
                template.value.name = templateNameBeforeSave.value;
            }
            saveTemplateClicked.value = false;
        }

        async function closeModal() {
            if (_.isEqual(template.value, templateBeforeEdit.value) == false) {
                const result = await swal.fire({
                    icon: 'warning',
                    title: t('template.warning.changes-not-saved'),
                    showConfirmButton: true,
                    showCancelButton: true,
                    showCloseButton: true,
                    customClass: {
                        confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                        cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                    },
                    buttonsStyling: false,
                    confirmButtonText: t('button.yes'),
                    cancelButtonText: t('button.no')
                });
                if (!result.isConfirmed) {
                    return;
                }
            }
            if (fileInput.value.files) {
                const dt = new DataTransfer();
                fileInput.value.files = dt.files;
            }
            close();
        }

        const submitForm = async (event: any) => {
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                return;
            }

            if (props.fromManagement) {
                await CreateEditTemplate();
            } else {
                const req: SendEmailSmsAction = {
                    template: template.value!,
                    date: date.value,
                    slotId: slotId.value,
                    productId: productId.value
                };
                emit('emitTemplate', req);
            }
        };

        const uploadFile = async () => {
            if (props.fromManagement == true) {
                if (fileInput.value && fileInput.value.files.length > 0) {
                    files.value.push(...fileInput.value.files);
                }
            } else {
                swal.showLoading();
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                };

                const filesForUpload = fileInput.value.files;
                if (filesForUpload.length > 0) {
                    const fileTypes: string[] = ['doc', 'docx', 'xls', 'xlsx', 'txt', 'pdf', 'png', 'ppt', 'jpg', 'gif', 'mp3', 'mp4'];
                    let errorFileTypeSize = false;
                    fileInput.value.files.forEach((file: any) => {
                        const fileExt = file.name.split('.').pop();
                        if (!fileTypes.includes(fileExt)) {
                            swal.fire({
                                icon: 'error',
                                text: 'Error file type'
                            });
                            errorFileTypeSize = true;
                            return;
                        }

                        if (file.size > 5242880) {
                            swal.fire({
                                icon: 'error',
                                text: 'File size should be less or equal to 5 MB'
                            });
                            errorFileTypeSize = true;
                            return;
                        }
                    });

                    if (errorFileTypeSize) {
                        errorFileTypeSize = false;
                        return;
                    }
                }

                for (const file of filesForUpload) {
                    const fd = new FormData();

                    fd.append('file', file, file.name);
                    const responsePromise = api.SaveMessageTemporaryAttachment(fd, config);
                    await q.delay(400);
                    const response = await responsePromise;
                    if (response.errorMessage) {
                        swal.fire({
                            icon: 'error',
                            text: response.errorMessage || 'error'
                        });
                        return;
                    }

                    if (response.data) {
                        template.value!.attachments.push(response.data);
                    }
                }

                swal.close();
            }
        };

        const removeFile = async (index: number, fromAttachments: boolean) => {
            if (fromAttachments) {
                swal.showLoading();
                const templateAttachment = template.value?.attachments[index];
                if (!templateAttachment) {
                    return;
                }
                const resposne = await api.deleteMessageTempalteAttachmnent(templateAttachment.id);
                await q.delay(400);
                if (resposne.errorMessage) {
                    swal.fire({
                        icon: 'error',
                        text: resposne.errorMessage
                    });
                    return;
                }
                template.value?.attachments.splice(index, 1);
                swal.close();
            } else {
                const tempFiles: File[] = [];
                const dt = new DataTransfer();
                for (let i = 0; i < files.value.length; i++) {
                    const file = files.value[i];
                    if (index !== i) {
                        dt.items.add(file);
                        tempFiles.push(file);
                    }
                }
                fileInput.value.files = dt.files;
                files.value = tempFiles;
            }
        };

        function getDocIcon(path: string) {
            if (path.endsWith('.txt')) {
                return 'icon-txt-document';
            }
            if (path.endsWith('.pdf')) {
                return 'icon-pdf';
            }
            if (path.endsWith('.xlsx') || path.endsWith('.xls')) {
                return 'icon-xls';
            }
            if (path.endsWith('.docx') || path.endsWith('.doc')) {
                return 'icon-docx';
            }
            return '';
        }

        async function ShowInputTemplateName() {
            swal.fire({
                title: t('title.message-template-name'),
                input: 'text',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: t('button.save-v2'),
                cancelButtonText: t('send-sms-email.cancel'),
                showLoaderOnConfirm: true,
                allowOutsideClick: () => !swal.isLoading(),
                preConfirm: async templateName => {
                    if (templateName == '') {
                        swal.showValidationMessage(t('sms-email-management.template-error.empty-template-name'));
                        return false;
                    }
                    if (templateName.length < 2) {
                        swal.showValidationMessage(t('sms-email-management.template-error.length-template-name'));
                        return false;
                    }
                    await q.delay(200);
                    template.value!.id = '';
                    const templateToSave: MessageTemplate = JSON.parse(JSON.stringify(template.value));
                    templateToSave.name = templateName;
                    const responsePromise = api.createOrEditMessageTemplate(templateToSave);
                    await q.delay(400);
                    const response = await responsePromise;
                    if (response.errorMessage) {
                        swal.showValidationMessage(response.errorMessage || 'error');
                        return false;
                    }

                    template.value = response.data;
                    templateNameBeforeSave.value = template.value?.name ?? '';
                    saveTemplateClicked.value = false;
                    return true;
                }
            });
        }

        return {
            isOpen,
            open,
            close,
            isNew,
            submitForm,
            template,
            addKeyToText,
            textArea,
            useDefinedTemplateClicked,
            useDefinedTemplate,
            templates,
            saveTemplateClicked,
            selectTemplate,
            saveTemplate,
            cancelEdit,
            uploadFile,
            removeFile,
            fileInput,
            files,
            getDocIcon,
            templateOptions,
            loading,
            templateForm,
            selectedTemplate,
            ShowInputTemplateName,
            isValidTemplate,
            closeModal
        };
    }
});
