
import { defineComponent, watch, ref, nextTick } from 'vue';
import InputMask from 'primevue/inputmask';

export default defineComponent({
    props: {
        name: {
            type: String
        },
        invalid: {
            type: Boolean,
            default: false
        },
        modelValue: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            default: '09:00'
        }
    },
    components: { InputMask },
    emits: ['input', 'change', 'update:modelValue'],
    setup(props, { emit }) {
        const value = ref(props.modelValue);
        watch(
            () => props.modelValue,
            x => {
                value.value = x;
            }
        );

        async function validateDate() {
            await nextTick();
            await nextTick();
            const val = value.value;
            if (!val) return;
            const [hours, mins] = val.split(':');
            const parsedHours = parseInt(hours.replaceAll('_', '0'));
            const parsedMins = parseInt(mins.replaceAll('_', '0'));
            const bestMins = Math.min(parsedMins, 59);
            const bestHours = Math.min(parsedHours, bestMins > 0 ? 23 : 24);
            if (hours != '__' && parsedHours != bestHours) {
                value.value = '';
            } else if (mins != '__' && parsedMins != bestMins) {
                value.value = '';
            }
            emit('input', value.value);
            if (value.value && !value.value.includes('_')) {
                emit('change', value.value);
            }
        }
        return {
            value,
            validateDate
        };
    }
});
