import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, vModelCheckbox as _vModelCheckbox, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "workdays" }
const _hoisted_2 = {
  key: 0,
  class: "icon-f icon-check put-in-corner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.weekDayNames, (weekshort, weekname) => {
      return (_openBlock(), _createBlock("div", {
        class: ["week-day", { disabled: _ctx.disabledDays && _ctx.disabledDays.includes(weekshort) }],
        key: weekshort + weekname
      }, [
        _withDirectives(_createVNode("input", {
          type: "checkbox",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.value = $event)),
          id: _ctx.guid + weekname,
          class: "week-day-checkbox",
          value: weekshort,
          disabled: _ctx.readonly || (_ctx.disabledDays && _ctx.disabledDays.includes(weekshort)),
          onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:modelValue', _ctx.value)))
        }, null, 40, ["id", "value", "disabled"]), [
          [_vModelCheckbox, _ctx.value]
        ]),
        _createVNode("label", {
          for: _ctx.guid + weekname
        }, [
          _createTextVNode(_toDisplayString(_ctx.$t('weekday.' + weekshort.toLowerCase())) + " ", 1),
          (_ctx.value.includes(weekshort) && _ctx.withCheck)
            ? (_openBlock(), _createBlock("i", _hoisted_2))
            : _createCommentVNode("", true)
        ], 8, ["for"])
      ], 2))
    }), 128))
  ]))
}